import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TemplateHorizontalRule } from '../template/templateHorizontalRule';

class FeeDetailContainer extends Component {
  formatFeeNumber(fee, feeType, currency) {
    // depending on the fee type, if the number is meant to be a percentage, return it as a percentage multiplied by 100 and rounded to 2 decimal places (if needed)
    // otherwise return fee as a string with 2 decimal places only if there are any decimal places, otherwise return as an integer
    const decimalPlaces = fee % 1 !== 0 ? 2 : 0;
    if (feeType === 'flatPercentage' || feeType === 'bracketAndFlatPercentage' || feeType === 'flatPercentageAndFixedValue') {
      return `${(fee * 100).toFixed(decimalPlaces)}%`;
    }
    return `${currency} ${fee.toFixed(decimalPlaces)}`;
  }
  formatFee(feeIfNotCovered, feeIfCovered, feeType, alternateFee, lowerBound, upperBound, currency, isRegularDonation) {
    // feeType can be FlatPercentage, FixedValue, FlatPercentage, 
    //  BracketAndFixedValue, BracketAndFlatPercentage, FlatPercentageAndFixedValue
    // feeIfNotCovered and feeIfCovered is either a number or a percentage value (between 0 and 1) depending on the type
    //  and each value is dependent on whether the donor/buyer covered the fee on the purchase/donation
    // alternateFee is a number which only applies on FlatPercentageAndFixedValue
    // lowerBound applies on bracket fees (minimum value)
    // upperBound applies on bracket fees (maximum value)
    // currency is the currency code (e.g. USD, EUR, etc.)
    // this function should return a string properly describing the fee

    let feeDescription = '';
    if (lowerBound != null) {
      feeDescription += `From ${this.formatFeeNumber(lowerBound, 'fixedValue', currency)} `;
    }
    if (upperBound) {
      feeDescription += `up to ${this.formatFeeNumber(upperBound, 'fixedValue', currency)} `;
    }
    if (feeDescription) {
      feeDescription += ' --- the fee is';
    }
    const feeIfNotCoveredFormatted = this.formatFeeNumber(feeIfNotCovered, feeType, currency);
    const feeIfCoveredFormatted = this.formatFeeNumber(feeIfCovered, feeType, currency);
    const alternateFeeValue = alternateFee ? this.formatFeeNumber(alternateFee, 'fixedValue', currency) : null;
    switch(feeType) {
      case 'flatPercentage':
      case 'fixedValue':
      case 'bracketAndFixedValue':
      case 'bracketAndFlatPercentage':
        if (isRegularDonation) {
          return `${feeDescription} ${feeIfCoveredFormatted} (when covered)`;
        }
        return `${feeDescription} ${feeIfNotCoveredFormatted} (when not covered) & ${feeIfCoveredFormatted} (when covered)`;
      case 'flatPercentageAndFixedValue':
        if (isRegularDonation) {
          return `${feeDescription} ${feeIfCoveredFormatted} + ${alternateFeeValue} (when covered)`;
        }
        return `${feeDescription} ${feeIfNotCoveredFormatted} + ${alternateFeeValue} (when not covered) & ${feeIfCoveredFormatted} + ${alternateFeeValue} (when covered)`;
      default:
        return 'Unknown fee type';
    }
  }

  render() {
    const feeDetail = this.props.value;
    // feeDetail is an object with the following structure:
    //  donation: { campaignFees, eventFees, organizationFees }
    //  donationSubscription: { campaignFees, eventFees, organizationFees }
    //  sale: { campaignFees, eventFees, organizationFees }
    //  saleInstallment: { campaignFees, eventFees, organizationFees }
    // create a list of fees with a description alongside each one, like so: 'Campaign - Donation fees', feeDetail.donation?.campaignFees

    const fees = [
      { key: 'Campaign - Donation', value: feeDetail?.donation?.campaignFees },
      { key: 'Campaign - Recurring giving', value: feeDetail?.donationSubscription?.campaignFees },
      { key: 'Campaign - Sales', value: feeDetail?.sale?.campaignFees },
      { key: 'Campaign - Sale Installments', value: feeDetail?.saleInstallment?.campaignFees },
      { key: 'Organization - Donation', value: feeDetail?.donation?.organizationFees },
      { key: 'Organization - Recurring giving', value: feeDetail?.donationSubscription?.organizationFees },
      { key: 'Organization - Sales', value: feeDetail?.sale?.organizationFees },
      { key: 'Organization - Sale Installments', value: feeDetail?.saleInstallment?.organizationFees },
      { key: 'Event - Donation', value: feeDetail?.donation?.eventFees },
      { key: 'Event - Recurring giving', value: feeDetail?.donationSubscription?.eventFees },
      { key: 'Event - Sales', value: feeDetail?.sale?.eventFees },
      { key: 'Event - Sale Installments', value: feeDetail?.saleInstallment?.eventFees },
    ];
    const isValidFees = fees.filter(d => d.value && d.value.length > 0).length > 0;
    return (
      <div className="ui container fee-detail">
        <TemplateHorizontalRule />
        <h3>Custom fee detail</h3>
        {isValidFees && (
          <div>
          {fees.filter(d => d.value && d.value.length > 0).map((fee) => (
            <div>
              <h4>{fee.key}</h4>
              <ul>
                {fee.value?.map((donationFee) => {
                  const keyContainsDonation = fee.key.includes('Donation') && !donationFee.isSilent;
                  return (
                    <li> 
                      {(donationFee.isSilent) ? 'Silent fee: ' : 'Standard fee: '}
                      {this.formatFee(donationFee.feeIfNotCovered, donationFee.feeIfCovered, donationFee.feeType, 
                          donationFee.alternateFee, donationFee.bracket?.from, donationFee.bracket?.to, 
                          this.props.currency, keyContainsDonation)}
                    </li>
                  );
              })}
              </ul>
            </div>
          ))}
          </div>
        )}
        {!isValidFees && (
          <p>No custom fees found. Standard Grassrootz fees apply.</p>
        )}
        <TemplateHorizontalRule />
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: ownProps.value,
    currency: ownProps.currency,
  };
};

const FeeDetail = connect(mapState)(FeeDetailContainer);
export default FeeDetail;
