// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

// Actions
import * as recurringDonationActions from '../reducer';
import * as generalActions from '../../../modules/general';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { formBuilderActions } from '../../../components/formbuilder/store';
import { organizationActions } from '../../organization/store/organizationActions';
import { cardActions } from '../../../modules/card';

// Helpers
import { Record as RecordHelper } from '../../../modules/bin/utility';
import get from 'lodash.get';
import clear from '../../../components/clear';
import { TemplateOptions } from '../../../components/template/templateOptions';

// Components
import { Grid } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import RecurringDonationTemplate from '../template';

// Constants
import config from '../../../constants';
import { recurringContract } from '../constants';
import { paymentMethod } from '../../../constants/paymentMethod';

class RecurringDonationEdit extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    props.getRecurringDonationRecord(id);
  }

  componentDidUpdate() {
    if (
      RecordHelper.isRecordReady(this.props.record) &&
      RecordHelper.notStarted(this.props.organizationRecord)
    ) {
      const recurringDonation = get(this.props, 'record.data.data');
      const organizationId = get(recurringDonation, 'organization.id');
      this.props.getOrganizationRecord(organizationId);
    }
  }

  goToRecurringView = () => {
    this.props.history.push(config.DEFAULT_RECURRING_URL);
  };

  onSave = (data) => {
    if (
      data.payment
        && data.payment.number?.complete
        && data.payment.expiry?.complete
        && data.payment.cvc?.complete
    ) {
      this.props.setPaymentRequest(recurringContract.payment);
    } else {
      this.props.updateRecurringDonation(data);
    }
  };

  clearMessages = () => {
    this.props.clearRecordMessages('recordEdit');
  };

  isAllRecordsReady = () => {
    return (
      RecordHelper.isRecordReady(this.props.record) &&
      RecordHelper.isRecordReady(this.props.organizationRecord)
    );
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const recurringDonation = get(this.props, 'record.data.data');
    const organization = get(this.props, 'organizationRecord.data.data');

    if (!this.props.isTemplateInited) {
      const templateOptions = new TemplateOptions()
        .setModel(recurringDonation)
        .isEdit()
        .disable(recurringContract.organization)
        .disable(recurringContract.frequency)
        .disable(recurringContract.donationCountLimit);

      const coverFee = get(recurringDonation, recurringContract.coverFee);
      if (coverFee) {
        const fee = get(recurringDonation, recurringContract.fee);
        const amount = get(recurringDonation, recurringContract.amount);
        templateOptions.setValue(recurringContract.amount, amount - fee);
      } else {
        templateOptions.hide('amountWithFee');
      }

      if (
        organization &&
        organization.paymentPlatforms &&
        organization.paymentPlatforms.length !== 0
      ) {
        templateOptions.setValue(
          'accountKey',
          organization.paymentPlatforms[0].accountKey
        );
      }
      templateOptions.setValue('primaryPath', organization.urlPath);

      if (
        get(recurringDonation, 'payment.paymentMethod') ===
        paymentMethod.bankAccount
      ) {
        templateOptions.hide('payment');
      }

      this.props.setTemplateModel(templateOptions.getWithRule());
    }

    const isSaving =
      RecordHelper.isRecordLoading(this.props.recordEdit) ||
      this.props.paymentInProgress;

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToRecurringView}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <h1>
                <Translate value="recurring-donation.edit.title" />
              </h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <RecurringDonationTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ recurring, templateBuilderEx, organization, card }) => {
  const record = recurring.record || {};

  return {
    record,
    recordEdit: recurring.recordEdit,
    isTemplateInited: templateBuilderEx.inited,
    organizationRecord: organization.record,
    paymentInProgress: get(
      card,
      `${recurringContract.payment}.paymentInProgress`
    )
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(recurringDonationActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch),
    ...bindActionCreators(generalActions, dispatch),
    getOrganizationRecord: bindActionCreators(
      organizationActions.getOrganizationRecord,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch),
    setPaymentRequest: (path) => {
      dispatch(cardActions.setPaymentRequest(path));
    }
  };
};

/** Connects component to Redux store */
const EditRecurringDonationContainer = clear(
  connect(mapState, mapDispatch)(RecurringDonationEdit)
);
export default EditRecurringDonationContainer;
