// React / Redux / Related
import React, { Component } from 'react';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

// Actions
import * as programActions from '../store/vipProgramActions';

// Components
import ListsViewContainer from './listview';
import { Common } from '../../../components';
import clear from '../../../components/clear';
import BasePage from '../../../components/common/basePageView';
import { Record } from '../../../modules/bin/utility';
import ContextMenu from '../../../components/common/grid/contextMenu';
import { ChangeAvailabilityModal } from '../changeAvailabilityModal';

// Constants
import config from '../../../constants/index';
import { gridTabs, i18nKeys } from '../constants';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';
import { exportFileNames } from '../../../constants/exportFiles';

// ----------------------------------------------------------------------

/** A page to view all campaigns in a list */
class EventAmbassadorView extends Component {
  constructor(props) {
    super(props);

    const eventId = get(props, 'match.params.eventId');

    props.getEventAmbassadorProgramByEventRecord(eventId);

    props.getTotalCountPrograms(
      'CampaignAmbassadorProgram/aggregate',
      'eventId',
      eventId
    );

    props.getTotalCountApplications(
      'eventAmbassador/aggregate',
      'eventId',
      eventId
    );
    props.getTotalCountPendingApprovalProgramsByReceivedStatus(
      'eventAmbassador/aggregate',
      'eventId',
      eventId
    );
    props.getTotalCountVIPProgramsByClaimedStatus(
      'eventAmbassador/aggregate',
      'eventId',
      eventId
    );
    props.getTotalCountVIPProgramsByAcceptedStatus(
      'eventAmbassador/aggregate',
      'eventId',
      eventId
    );
  }

  getProgramsContextMenu = () => {
    return (
      <ContextMenu>
        {this.props.isSysAdmin && (
          <ContextMenu.Item
            key={CONTEXT_MENU_KEYS.PROGRAM.TOGGLE_CAMPAIGN_PROGRAMS.key}
            label={(id, item) => get(item, 'availability.newEntries')
              ? I18n.t('eventPrograms.list.context.close-applications')
              : I18n.t('eventPrograms.list.context.open-applications')
            }
            contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.TOGGLE_CAMPAIGN_PROGRAMS}
            onClick={(id, item) => {
              this.props.toggleChangeAvailabilityModal(
                get(item, 'availability.newEntries') ? "CloseApplications" : "OpenApplications",
                id
              );
            }}
          />
        )}
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_CAMPAIGN_PROGRAMS.key}
          label={I18n.t('eventPrograms.list.context.view-applications')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_CAMPAIGN_PROGRAMS}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_CAMPAIGN_PROGRAM.key}
          label={I18n.t('eventPrograms.list.context.edit-program')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_CAMPAIGN_PROGRAM}
        />
      </ContextMenu>
    );
  };

  getEventApplicationsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('eventApplications.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('eventApplications.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  getEventPendingApprovalProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t(
            'eventPendingApprovalPrograms.list.context.edit-application'
          )}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t(
            'eventPendingApprovalPrograms.list.context.view-fundraiser'
          )}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  getEventVipProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('eventVipPrograms.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('eventVipPrograms.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
          isHiden={(item) => !Boolean(item.fundraiser)}
        />
      </ContextMenu>
    );
  };

  onEditEventAmbassadorProgramClick = () => {
    const eventId = get(this.props, 'match.params.eventId');

    this.props.history.push(`/vip-program/edit/${eventId}/setup`);
  };

  onCreateOrganizationClick = () => {
    const eventId = get(this.props, 'match.params.eventId');

    this.props.history.push(`/vip-program/${eventId}/add-organization/setup`);
  };

  /**
   * Renders the component
   */
  render() {
    const eventId = get(this.props, 'match.params.eventId');
    const baseFilter = { key: 'eventId', operator: 'EqualTo', value: eventId };

    const vipProgramsFilters = [
      { key: 'eventId', operator: 'EqualTo', value: [eventId] },
      { key: 'status', operator: 'InList', value: ['Accepted', 'Claimed'] }
    ];
    const pendingApprovalProgramsFilters = [
      { key: 'eventId', operator: 'EqualTo', value: eventId },
      { key: 'status', operator: 'EqualTo', value: 'Received' }
    ];

    const eventAmbassadorData = get(
      this.props.eventAmbassadorProgramRecord,
      'data.data'
    );

    const isLoading =
      Record.isRecordReady(this.props.eventAmbassadorProgramRecord) &&
      Record.isRecordReady(this.props.totalCountProgramsRecord) &&
      Record.isRecordReady(this.props.totalCountApplicationsRecord) &&
      Record.isRecordReady(
        this.props.totalCountPendingApprovalProgramsByReceivedStatusRecord
      ) &&
      Record.isRecordReady(
        this.props.totalCountVIPProgramsByClaimedStatusRecord
      ) &&
      Record.isRecordReady(
        this.props.totalCountVIPProgramsByAcceptedStatusRecord
      );

    const eventAmbassadorName = get(eventAmbassadorData, 'name');
    const eventAmbassadorAvailability = get(eventAmbassadorData, 'availability.newEntries');
    const eventName = get(eventAmbassadorData, 'event.name');
    const eventImage = get(eventAmbassadorData, 'event.mainImagePath');

    const metrics = get(eventAmbassadorData, 'metrics');

    return (
      <BasePage>
        <div>
          <Common.PageHeader
            loading={!isLoading}
            name={eventName}
            image={eventImage}
            afterBreadcrumbs={
              <>
                <Icon
                  name="circle"
                  style={{
                    marginLeft: "1rem",
                    color: eventAmbassadorAvailability ? "#56B68B" : "#757575"
                  }}
                />
                Applications {eventAmbassadorAvailability ? "Open" : "Closed"}
              </>
            }
            headerRightPanel={
              <div className="page-header-right-panel">
                <Common.Button onClick={this.onEditEventAmbassadorProgramClick}>
                  <Translate value={i18nKeys.EDIT_PROGRAM_EVENT_VIEW_BUTTON} />
                </Common.Button>
                <Common.Button onClick={this.onCreateOrganizationClick} primary>
                  <Translate value={i18nKeys.ADD_ORGANIZATION_BUTTON} />
                </Common.Button>
              </div>
            }
          >
            <Link to={config.DEFAULT_PROGRAMS_URL} push>
              <Common.PageHeader.Breadcrumb
                label={I18n.t(i18nKeys.VIEW_LABEL)}
              />
            </Link>
            <Common.PageHeader.Breadcrumb label={eventAmbassadorName} />
          </Common.PageHeader>
        </div>
        <ListsViewContainer
          baseFilter={baseFilter}
          filterPath="vip-program/event"
          metrics={metrics}
          programsGridKey={gridTabs.EVENT_PROGRAMS}
          programsExportName={exportFileNames.EVENT_PROGRAMS}
          programsExportEndpoint={'CampaignAmbassadorProgram'}
          programsContextMenu={this.getProgramsContextMenu()}
          applicationsGridKey={gridTabs.EVENT_APPLICATIONS}
          applicationsExportName={exportFileNames.EVENT_APPLICATIONS}
          applicationsExportEndpoint={'EventAmbassador'}
          applicationsContextMenu={this.getEventApplicationsContextMenu()}
          pendingApprovalProgramsGridKey={
            gridTabs.EVENT_PENDING_APPROVAL_PROGRAMS
          }
          pendingApprovalProgramsExportName={
            exportFileNames.EVENT_PENDING_APPROVAL_PROGRAMS
          }
          pendingApprovalProgramsExportEndpoint={'EventAmbassador'}
          pendingApprovalProgramsContextMenu={this.getEventPendingApprovalProgramsContextMenu()}
          pendingApprovalProgramsFilters={pendingApprovalProgramsFilters}
          vipProgramsGridKey={gridTabs.EVENT_VIP_PROGRAMS}
          vipProgramsExportName={exportFileNames.EVENT_VIP_PROGRAMS}
          vipProgramsExportEndpoint={'EventAmbassador'}
          vipProgramsContextMenu={this.getEventVipProgramsContextMenu()}
          vipProgramsFilters={vipProgramsFilters}
        />
        <ChangeAvailabilityModal />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = (state) => {
  return {
    eventAmbassadorProgramRecord: state.program.eventAmbassadorProgramRecord,
    totalCountProgramsRecord: state.program.totalCountProgramsRecord,
    totalCountApplicationsRecord: state.program.totalCountApplicationsRecord,
    totalCountPendingApprovalProgramsByReceivedStatusRecord:
      state.program.totalCountPendingApprovalProgramsByReceivedStatusRecord,
    totalCountVIPProgramsByClaimedStatusRecord:
      state.program.totalCountVIPProgramsByClaimedStatusRecord,
    totalCountVIPProgramsByAcceptedStatusRecord:
      state.program.totalCountVIPProgramsByAcceptedStatusRecord,
    isSysAdmin: state.session.isSystemAdmin,
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(programActions, dispatch);

  return dispatchers;
};

/** Connects component to Redux store */
const EventAmbassadorViewPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(EventAmbassadorView))
);
export default EventAmbassadorViewPageContainer;
