import React, { Component, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import * as campaignActions from '../store/campaignActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as generalActions from '../../../modules/general';
import { formBuilderActions } from '../../../components/formbuilder/store';

import { cloneDeep } from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import update from '../../../helpers/update';
import { Record as RecordHelper } from '../../../modules/bin/utility';
import clear from '../../../components/clear';
import {
  defaultWebTrackingElements,
  getTrackersList
} from '../../../helpers/webTrackingHelper';
import {
  getDefaultAboutElements,
  getDefaultFundraiserAndTeamAboutElements,
  getDefaultThankYouElements
} from '../../../helpers/pageAboutHelper';
import { mergeTemplate, remapAndUnset } from '../../../helpers/templateHelper';
import { sortArray, isArrayWithItems } from '../../../helpers/arrayHelper';
import { getState } from '../../../components/template/templateHelper';
import { isChangeStatusValid } from '../../../helpers/statusWorkflowHelper';

import { Grid, Dropdown, Message } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import RecordResultMessage from '../../../components/common/recordResult';
import CampaignTemplate from '../create/templateEx';
import BasePage from '../../../components/common/basePageView';
import ConfirmationModal from '../../../components/common/confirmationModal';
import SavedEntityModal from '../../../components/common/savedEntityModal';
import Text from '../../../components/common/text';
import EntityModal from '../../../components/errors/entityModal';

import {
  campaignStatuses,
  paths,
  formBuilderKeys,
  ticketIssuingStyle,
  mandatoryOptions,
  ticketPurchaseFundraisingStyles,
  defaultTicketPurchaseFundraisingStyle,
  joiningExperience,
  paymentFeeCalculation,
  donationType,
  couponsRequestPageSize
} from '../constants';
import {
  campaignContract,
  campaignTypes,
  templates
} from '../create/templates';
import {
  generalFormBuilderPath,
  customFieldCategory
} from '../../../constants/formBuilder';
import config from '../../../constants/index';
import { templateKeys } from '../../../constants/templateKeys';
import { giftMatchingConditionUpdater } from '../../../helpers/giftMatchingHelper';
import { FitnessContract, FitnessContractValues } from '../create/fitness/constants';

class CampaignEdit extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    
    props.getRecord(id);
    if (props.isSystemAdmin) {
      props.getFeeRecord(id);
    }

    if (!this.props.isEventAdmin) {
      props.getTicketsRecord(this.getInitialSearchRequest(id));
      props.getCouponsRecord(
        this.getInitialSearchRequest(id, couponsRequestPageSize)
      );
    }

    props.getTotalAmountTeams(id);
    props.getTotalAmountFundraisers(id);
    props.getTotalAmountCollections(id);

    this.state = {
      isConfirmModalOpened: false,
      isErrorModalOpened: false,
      newStatus: ''
    };
  }

  getInitialSearchRequest = (id, pageSize = 75) => {
    return {
      pageSize: pageSize,
      filters: [
        { key: 'campaignId', operator: 'EqualTo', value: id },
        { key: 'enabled', operator: 'EqualTo', value: true }
      ],
      includeDataTemplate: true,
      resultType: 'Full'
    };
  };

  onSave(data) {
    this.props.updateRecord(data);
  }

  /**
   * Clear all record messages
   */
  clearMessages = () => {
    this.props.clearRecordMessages('recordEdit');
    this.props.clearRecordMessages('recordComplete');
    this.props.clearRecordMessages('statusRecord');
  };

  onModalClose = () => {
    this.props.onValueChange('saveModalOpen', false);
    this.props.onValueChange('campaignRequestErrors.errors', []);
    this.setState({ isConfirmModalOpened: false, isErrorModalOpened: false });
  };

  onDropdownItemChange = (event, { value }) => {
    const status = this.getCampaignStatus();
    if (value === status) {
      return;
    }

    if (isChangeStatusValid(status, value)) {
      this.setState({
        newStatus: value,
        isConfirmModalOpened: true
      });
    } else {
      this.setState({
        newStatus: value,
        isErrorModalOpened: true
      });
    }
  };

  getCampaignStatus = () => {
    const savedStatus = get(this.props, 'statusRecord.data.data.status');
    const initedStatus = get(this.props, 'record.data.data.status');
    return savedStatus || initedStatus;
  };

  updateCampaignStatus = (newStatus) => {
    const status = newStatus || this.state.newStatus;
    this.props.updateCampaignStatus(this.props.record.data.data.id, {
      status: status
    });
    this.props.setResultValue(paths.CAMPAIGN_STATUS, status);
  };

  isAllRecordsReady = () => {
    return (
      RecordHelper.isRecordReady(this.props.record) &&
      (this.props.isEventAdmin ||
        RecordHelper.isRecordReady(this.props.ticketsRecord)) &&
      (this.props.isEventAdmin ||
        RecordHelper.isRecordReady(this.props.couponsRecord)) &&
      RecordHelper.isRecordReady(this.props.totalFundraisersRecord) &&
      RecordHelper.isRecordReady(this.props.totalTeamsRecord) &&
      RecordHelper.isRecordReady(this.props.totalCollectionsRecord)
    );
  };

  getConfirmModalContent = () => {
    const { pagesCount, name } = this.props.record?.data?.data || {};
    const status = this.getCampaignStatus();
    const translationOptions = {
      pagesCount,
      campaignName: name,
      newStatus: this.state.newStatus,
      oldStatus: status
    };
    //the campaign has associated fundraisers
    if (pagesCount) {
      return (
        <Text>
          {I18n.t(
            `campaign.changing-status-message.${status}.associated`,
            translationOptions
          )}
          {I18n.t(`campaign.changing-status-message.${status}.default`)}
          <b> {I18n.t(`campaign.changing-status-message.${status}.bold`)}</b>
        </Text>
      );
    }
    return (
      <Text>
        {I18n.t(`campaign.changing-status-message.${status}.default`)}
        <b> {I18n.t(`campaign.changing-status-message.${status}.bold`)}</b>
      </Text>
    );
  };

  getErrorModalContent = () => {
    const translationOptions = {
      newStatus: this.state.newStatus,
      oldStatus: this.getCampaignStatus()
    };

    return (
      <Text>
        {I18n.t('campaign.cannot-change-status-message', translationOptions)}
      </Text>
    );
  };

  getError = (path) => (this.props.campaignRequestErrors.errors || []).find(
    (item) => this.getPayloadError(item) === path
  );

  getPayloadError = (item) => (item.payload.error || [])[0];

  getSavedModalContent = () => {
    const couponAndTicketModelErrors = this.props.campaignRequestErrors.errors;
    const showErrors = isArrayWithItems(couponAndTicketModelErrors);
    const ticketError = this.getError('ticketing.ticket.failed');
    const couponError = this.getError('ticketing.coupon.failed');
    const customEmailSettingError = this.getError('donations.customEmailSetting.failed');

    return (
      <Fragment>
        {showErrors && (
          <div className="save-modal-error">
            {ticketError && (
              <Message negative>
                {I18n.t(`error-messages.${this.getPayloadError(ticketError)}`)}
              </Message>
            )}
            {couponError && (
              <Message negative>
                {I18n.t(`error-messages.${this.getPayloadError(couponError)}`)}
              </Message>
            )}
            {customEmailSettingError && (
              <Message negative>
                {I18n.t(`error-messages.${this.getPayloadError(customEmailSettingError)}`)}
              </Message>
            )}
          </div>
        )}
        {!showErrors && (
          <Text>
            {I18n.t(`template.page.saved-modal.campaign-edit-message`).replace(
              '{campaignName}',
              get(this.props, 'record.data.data.name')
            )}
          </Text>
        )}
      </Fragment>
    );
  };
  getSavedModalButtons = () => {
    return (
      <Fragment>
        <Button
          className="primary"
          onClick={() => {
            window.open(get(this.props, 'record.data.data.urlFull'), '_blank');
          }}
        >
          {I18n.t('template.page.saved-modal.campaign-view-button')}
        </Button>
        <Button
          onClick={() => this.props.history.push(config.DEFAULT_CAMPAIGNS_URL)}
        >
          {I18n.t('template.page.saved-modal.campaign-return-button')}
        </Button>
      </Fragment>
    );
  };

  hideExtendedTicketingControls = (templateOptions) => {
    templateOptions.hide(campaignContract.ticketingThankYou);
    templateOptions.hide(campaignContract.ticketsButtonText);
    templateOptions.hide(campaignContract.enableSaleDonations);
    templateOptions.hide(campaignContract.ticketingDefaultDonationAmounts);
    templateOptions.hide(campaignContract.ticketsTitleText);
    templateOptions.hide(campaignContract.ticketingIntroductoryText);
    templateOptions.hide(campaignContract.tickets);
    templateOptions.hide(campaignContract.ticketsInformation);
    templateOptions.hide(campaignContract.ticketingInvoiceQuestions);
    templateOptions.hide(campaignContract.ticketIssuingStyle);
    templateOptions.hide(campaignContract.purchaseFundraisingEnabled);
    templateOptions.hide(campaignContract.merchandiseEnabled);
    templateOptions.hide(campaignContract.allowedSaleDonationTypes);
    templateOptions.hide(campaignContract.saleRecurringLimit);
    templateOptions.hide(campaignContract.paymentFeeCalculation);
    templateOptions.hide('recurring-sale-info-recurringAndOnceOff');
    templateOptions.hide('recurring-sale-info-onlyOnceOff');
    templateOptions.hide('recurring-sale-info-onlyRecurring');
  };

  hideTicketingFundraisingControls = (templateOptions) => {
    templateOptions.hide(campaignContract.allowTeamStep);
    templateOptions.hide(campaignContract.purchaseFundraisingIsMandatory);
    templateOptions.hide(campaignContract.purchaseFundraisingStyle);
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    let campaign = cloneDeep(get(this.props, 'record.data.data'));

    if (!this.props.isTemplateInited) {
      let tickets = get(this.props, 'ticketsRecord.data.data.list') || [];
      const coupons = get(this.props, 'couponsRecord.data.data.list') || [];

      const availableTickets = tickets.map((ticket) => ticket.id);
      const availableCoupons = coupons.map((coupon) => coupon.id);
      const availableCouponInstances = [];
      coupons.forEach((coupon) => {
        if (coupon.instances && coupon.instances.length > 0) {
          coupon.instances.forEach((instance) => {
            availableCouponInstances.push({
              instanceId: instance.id,
              couponId: coupon.id
            });
          });
        }
      });

      const templateOptions = templates[campaign.type.toLowerCase()]();
      const isEvent = !!campaign.event;

      const donationsFormBuilderKey = `${generalFormBuilderPath}.${customFieldCategory.DONATION}`;
      let donationsFormBuilder = isEvent
        ? get(campaign.event, donationsFormBuilderKey)
        : get(campaign, donationsFormBuilderKey);

      const fundraisersFormBuilderKey = `${generalFormBuilderPath}.${customFieldCategory.FUNDRAISING}`;
      let fundraisersFormBuilder = isEvent
        ? get(campaign.event, fundraisersFormBuilderKey)
        : get(campaign, fundraisersFormBuilderKey);

      const salesFormBuilderKey = `${generalFormBuilderPath}.${customFieldCategory.SALES}`;
      const salesFormBuilder = get(campaign, salesFormBuilderKey);

      if (isArrayWithItems(tickets)) {
        tickets.forEach((item) => {
          const ticketFormbuilder = get(item, generalFormBuilderPath);
          if (ticketFormbuilder) {
            this.props.formBuilderActions.setFormBuilderModel(
              ticketFormbuilder
            );
          }

          if (item.availableUntil) {
            const index = tickets.findIndex((el) => el.id === item.id);
            tickets = update.set(
              tickets,
              `${index}.availableUntil`,
              moment(item.availableUntil).format(config.DEFAULT_DATE_FORMAT)
            );
          }

          if (item.availableFrom) {
            const index = tickets.findIndex((el) => el.id === item.id);
            tickets = update.set(
              tickets,
              `${index}.availableFrom`,
              moment(item.availableFrom).format(config.DEFAULT_DATE_FORMAT)
            );
          }
        });
      }

      campaign = update(campaign, {
        tickets: { $set: sortArray(tickets, 'template.value.sequence') },
        availableTickets: { $set: availableTickets },
        coupons: { $set: coupons },
        availableCoupons: { $set: availableCoupons },
        availableCouponInstances: { $set: availableCouponInstances }
      });

      //Re-maping old paths
      campaign = remapAndUnset(
        campaign,
        'template.value.ticketing',
        campaignContract.simpleTicketingEnabled
      );
      campaign = remapAndUnset(
        campaign,
        'template.value.purchaseTicketUrl',
        campaignContract.purchaseTicketUrl
      );

      const allowTicketing = get(campaign, campaignContract.allowTicketing);
      const allowMerchandise = get(campaign, campaignContract.allowMerchandise);
      const allowFundraisersResourcesPage = get(campaign, campaignContract.fundraisersResourcesPageEnabled);
      const allowtermsAndConditionSetupTab = get(campaign, campaignContract.allowtermsAndConditionSetupTab);

      if (
        get(campaign, campaignContract.simpleTicketingEnabled) === undefined
      ) {
        campaign = update.set(
          campaign,
          campaignContract.simpleTicketingEnabled,
          false
        );
      }

      const simpleTicketingEnabled = get(
        campaign,
        campaignContract.simpleTicketingEnabled
      );
      const purchaseFundraisingEnabled = get(
        campaign,
        campaignContract.purchaseFundraisingEnabled
      );

      if (purchaseFundraisingEnabled === undefined) {
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingEnabled,
          true
        );
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingIsMandatory,
          mandatoryOptions.find((x) => x.value === false).value
        );
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingStyle,
          ticketPurchaseFundraisingStyles.find(
            (x) => x.value === defaultTicketPurchaseFundraisingStyle
          ).value
        );
      }

      if (get(campaign, campaignContract.fundraisersEnable) === false) {
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingEnabled,
          false
        );
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingIsMandatory,
          mandatoryOptions.find((x) => x.value === false).value
        );
        campaign = update.set(
          campaign,
          campaignContract.purchaseFundraisingStyle,
          ticketPurchaseFundraisingStyles.find(
            (x) => x.value === defaultTicketPurchaseFundraisingStyle
          ).value
        );
      }

      if (allowTicketing) {
        if (simpleTicketingEnabled) {
          this.hideExtendedTicketingControls(templateOptions);
          this.hideTicketingFundraisingControls(templateOptions);
        } else {
          if (purchaseFundraisingEnabled === false) {
            this.hideTicketingFundraisingControls(templateOptions);
          }
          templateOptions.hide(campaignContract.purchaseTicketUrl);
        }
      } else {
        this.hideExtendedTicketingControls(templateOptions);
        templateOptions.hide(campaignContract.purchaseTicketUrl);
        templateOptions.hide(campaignContract.simpleTicketingEnabled);
        templateOptions.hide('discountsTab');
        templateOptions.hide(campaignContract.termsAndConditionSetupTab);
        templateOptions.hide(campaignContract.termsAndConditionsCheckbox)
        templateOptions.hide(campaignContract.singleUserRegistrationMode)
        templateOptions.hide(campaignContract.merchandiseTab);
        this.hideTicketingFundraisingControls(templateOptions);
      }

      
      if (!allowFundraisersResourcesPage) {
        templateOptions.hide(campaignContract.fundraisersResourcesPage);
      }

      if (!allowMerchandise) {
        templateOptions.hide(campaignContract.merchandiseTab);
      }

      if (!allowtermsAndConditionSetupTab) {
        templateOptions.hide(campaignContract.termsAndConditionSetupTab);
      }

      const campaignPaymentFeeCalculation = get(
        campaign,
        campaignContract.paymentFeeCalculation
      );
      if (!campaignPaymentFeeCalculation) {
        campaign = update.set(
          campaign,
          campaignContract.paymentFeeCalculation,
          paymentFeeCalculation.onTopOfTicketPrice
        );
      }

      if (this.props.isEventAdmin) {
        templateOptions.hide('ticketingTab');
        templateOptions.hide('discountsTab');
        templateOptions.hide(campaignContract.merchandiseTab);
        templateOptions.hide(campaignContract.termsAndConditionSetupTab);
      }

      if (!this.props.isSystemAdmin) {
        templateOptions.hide(campaignContract.merchandiseEnabled);
      }

      const allowCoupons = get(campaign, campaignContract.allowCoupons);
      if (!allowCoupons) {
        templateOptions.hide(campaignContract.coupons);
      }

      const templateKey = get(campaign, 'template.key');
      if (templateKey === templateKeys.EVENT) {
        const campaignAbout = get(
          campaign,
          'template.value.defaultCampaignAbout'
        );
        campaign = update.set(campaign, campaignContract.about, campaignAbout);

        const eventTemplate = get(campaign, 'template');
        const campaignTemplate = mergeTemplate(
          { template: eventTemplate },
          campaignContract,
          templateKeys.CAMPAIGN
        );

        campaign = update(campaign, {
          template: { $set: campaignTemplate }
        });

        //Needed only for fix bug #2591
        campaign = update(campaign, {
          template: {
            value: {
              formBuilder: { $set: null }
            }
          }
        });
      }

      // if we have fundraiser already lined to campaign - disable control
      if (this.props.totalFundraisersRecord.data !== 0) {
        templateOptions.disable(campaignContract.fundraisersEnable);
      }

      // if we have teams already linked to campaign - disable control
      if (this.props.totalTeamsRecord.data !== 0) {
        templateOptions.disable(campaignContract.teamsEnable);
      }

      // if we have collections already linked to campaign - disable control
      if (this.props.totalCollectionsRecord.data !== 0) {
        templateOptions.disable(campaignContract.allowCollections);
      }

      const about = get(campaign, campaignContract.about);
      if (!about || typeof about.elements === 'undefined') {
        campaign = update.set(
          campaign,
          'template.value.about',
          getDefaultAboutElements()
        );
      }

      // Web Tracking
      const webTracking = get(campaign, campaignContract.webTracking);
      if (!webTracking) {
        campaign.template.value.webTracking = defaultWebTrackingElements;
      } else {
        campaign.template.value.webTracking = getTrackersList(webTracking);
      }

      const defaultFundraiserAbout = get(
        campaign,
        campaignContract.defaultFundraiserAbout
      );
      if (
        !defaultFundraiserAbout ||
        typeof defaultFundraiserAbout.elements === 'undefined'
      ) {
        campaign = update.set(
          campaign,
          'template.value.defaultFundraiserAbout',
          getDefaultFundraiserAndTeamAboutElements()
        );
      }

      const defaultTeamAbout = get(campaign, campaignContract.defaultTeamAbout);
      if (
        !defaultTeamAbout ||
        typeof defaultTeamAbout.elements === 'undefined'
      ) {
        campaign = update.set(
          campaign,
          'template.value.defaultTeamAbout',
          getDefaultFundraiserAndTeamAboutElements()
        );
      }

      const defaultCollectionAbout = get(
        campaign,
        campaignContract.defaultCollectionAbout
      );
      if (
        !defaultCollectionAbout ||
        typeof defaultCollectionAbout.elements === 'undefined'
      ) {
        campaign = update.set(
          campaign,
          'template.value.defaultCollectionAbout',
          getDefaultFundraiserAndTeamAboutElements()
        );
      }

      const defaultTicketingThankYou = get(
        campaign,
        campaignContract.ticketingThankYou
      );
      if (
        !defaultTicketingThankYou ||
        typeof defaultTicketingThankYou.elements === 'undefined'
      ) {
        campaign = update.set(
          campaign,
          campaignContract.ticketingThankYou,
          getDefaultThankYouElements()
        );
      }

      const fundraisersEnabled = get(
        campaign,
        campaignContract.fundraisersEnable
      );
      const teamsEnabled = get(campaign, campaignContract.teamsEnable);
      if (!fundraisersEnabled && !teamsEnabled) {
        templateOptions.hide('defaultAboutHeader');
        templateOptions.hide(campaignContract.purchaseFundraisingEnabled);
        this.hideTicketingFundraisingControls(templateOptions);
      }

      const ticketIssuingStyleValue = get(
        campaign,
        campaignContract.ticketIssuingStyle
      );

      if (!ticketIssuingStyleValue) {
        campaign = update.set(
          campaign,
          campaignContract.ticketIssuingStyle,
          ticketIssuingStyle.GroupedToBuyer
        );
      }

      if (campaign.event) {
        if (donationsFormBuilder) {
          donationsFormBuilder = update(donationsFormBuilder, {
            formKey: { $set: formBuilderKeys.CAMPAIGN_DONATIONS }
          });
        }

        if (fundraisersFormBuilder) {
          fundraisersFormBuilder = update(fundraisersFormBuilder, {
            formKey: { $set: formBuilderKeys.CAMPAIGN_FUNDRAISING }
          });
        }

        templateOptions.disable(campaignContract.fundraisersEnable);
        templateOptions.disable(campaignContract.fundraisersAllowSelfSignUp);
        templateOptions.disable(campaignContract.fundraisersDefaultText);
        templateOptions.disable(campaignContract.teamsEnable);
        templateOptions.disable(campaignContract.teamsAllowSelfSignUp);
        templateOptions.disable(campaignContract.teamsDefaultText);
        templateOptions.disable(
          `template.value.formBuilder.${customFieldCategory.FUNDRAISING}`
        );

        templateOptions.disable(campaignContract.nameForDonors);
        templateOptions.disable(
          `template.value.formBuilder.${customFieldCategory.DONATION}`
        );

        templateOptions.disable(campaignContract.allowTicketing);
        templateOptions.disable(campaignContract.purchaseTicketUrl);
        templateOptions.disable(campaignContract.allowAchievements);
        templateOptions.disable(campaignContract.allowMicroblogs);
      }

      //collections
      const campaignJoiningExperience = get(
        campaign,
        campaignContract.joiningExperience
      );
      if (!campaignJoiningExperience) {
        campaign = update.set(
          campaign,
          campaignContract.joiningExperience,
          joiningExperience.adminOnly
        );
      }

      if (!this.props.isSystemAdmin) {
        templateOptions.hide(campaignContract.isCollectionRequired);
      }

      const isCollectionsEnabled = get(campaign, 'collections.enabled');
      if (!isCollectionsEnabled) {
        templateOptions.hide(campaignContract.collectionsGeneralName);
        templateOptions.hide(campaignContract.allowCollectionDonations);
        templateOptions.hide(campaignContract.allowCollectionSales);
        templateOptions.hide(campaignContract.enableCollectionPageManager);
        templateOptions.hide(campaignContract.joiningExperience);
        templateOptions.hide(campaignContract.isCollectionRequired);
        templateOptions.hide(campaignContract.disableCollectionsPublicPages);
      }

      //recurring
      const disableDonationRecurringGiving = get(
        campaign,
        campaignContract.disableDonationRecurringGiving
      );
      const disableDonationOnceOffGiving = get(
        campaign,
        campaignContract.disableDonationOnceOffGiving
      );

      if (disableDonationRecurringGiving) {
        templateOptions.hide('recurring-donation-info-recurringAndOnceOff');
        templateOptions.hide('recurring-donation-info-onlyRecurring');
        templateOptions.hide(campaignContract.donationRecurringLimit);
        campaign = update.set(
          campaign,
          campaignContract.allowedDonationTypes,
          donationType.onlyOnceOff
        );
      } else if (disableDonationOnceOffGiving) {
        templateOptions.hide('recurring-donation-info-recurringAndOnceOff');
        templateOptions.hide('recurring-donation-info-onlyOnceOff');
        campaign = update.set(
          campaign,
          campaignContract.allowedDonationTypes,
          donationType.onlyRecurring
        );
      } else {
        templateOptions.hide('recurring-donation-info-onlyOnceOff');
        templateOptions.hide('recurring-donation-info-onlyRecurring');
        campaign = update.set(
          campaign,
          campaignContract.allowedDonationTypes,
          donationType.recurringAndOnceOff
        );
      }

      const disableSaleRecurringGiving = get(
        campaign,
        campaignContract.disableSaleRecurringGiving
      );
      const disableSaleOnceOffGiving = get(
        campaign,
        campaignContract.disableSaleOnceOffGiving
      );

      const disableOneClickSaleDonations = get(
        campaign,
        campaignContract.disableOneClickSaleDonations
      );
      campaign = update.set(
        campaign,
        campaignContract.enableSaleDonations,
        disableOneClickSaleDonations !== true
      );

      if (disableOneClickSaleDonations === true) {
        templateOptions.hide('recurring-sale-info-recurringAndOnceOff');
        templateOptions.hide('recurring-sale-info-onlyRecurring');
        templateOptions.hide('recurring-sale-info-onlyOnceOff');
        templateOptions.hide(campaignContract.saleRecurringLimit);
        templateOptions.hide(campaignContract.allowedSaleDonationTypes);
        campaign = update.set(
          campaign,
          campaignContract.allowedSaleDonationTypes,
          donationType.recurringAndOnceOff
        );
      } else if (disableSaleRecurringGiving) {
        templateOptions.hide('recurring-sale-info-recurringAndOnceOff');
        templateOptions.hide('recurring-sale-info-onlyRecurring');
        templateOptions.hide(campaignContract.saleRecurringLimit);
        campaign = update.set(
          campaign,
          campaignContract.allowedSaleDonationTypes,
          donationType.onlyOnceOff
        );
      } else if (disableSaleOnceOffGiving) {
        templateOptions.hide('recurring-sale-info-recurringAndOnceOff');
        templateOptions.hide('recurring-sale-info-onlyOnceOff');
        campaign = update.set(
          campaign,
          campaignContract.allowedSaleDonationTypes,
          donationType.onlyRecurring
        );
      } else {
        templateOptions.hide('recurring-sale-info-onlyOnceOff');
        templateOptions.hide('recurring-sale-info-onlyRecurring');
        campaign = update.set(
          campaign,
          campaignContract.allowedSaleDonationTypes,
          donationType.recurringAndOnceOff
        );
      }

      //fitness
      const isFitnessEnabled = get(campaign, FitnessContract.fitnessEnabled);
      if (!isFitnessEnabled) {
        campaign = update.set(campaign, FitnessContract.fitnessEnabled, false);

        FitnessContractValues.forEach((path) => {
          if (path === FitnessContract.fitnessEnabled) return;
          templateOptions.hide(path);
        });
      }

      const allowTracking = get(campaign, FitnessContract.allowPageTracking);
      if (!allowTracking) templateOptions.hide('fitnessFundraiserDates');

      // gift matching
      const originalGiftMatching = get(campaign, 'giftMatching');

      if (isArrayWithItems(originalGiftMatching)) {
        let giftMatching = giftMatchingConditionUpdater(
          originalGiftMatching,
          'startDateTime',
          'dateTime',
          'greaterOrEqualThan'
        );
        giftMatching = giftMatchingConditionUpdater(
          giftMatching,
          'endDateTime',
          'dateTime',
          'lesserOrEqualThan'
        );

        campaign = update.set(campaign, 'giftMatching', giftMatching);
      }

      //time and place
      const date = get(campaign, 'timeAndPlace.time.start');
      const end = get(campaign, 'timeAndPlace.time.end');
      const timeDetails = get(campaign, 'timeAndPlace.time.text');
      const includesTime = get(campaign, 'timeAndPlace.time.includesTime');
      const place = get(campaign, 'timeAndPlace.place.text');

      const isTimeAndPlaceEnabled = !!(date || timeDetails || place);

      if (!isTimeAndPlaceEnabled) {
        templateOptions.hide('dateAndTime');
        templateOptions.hide(campaignContract.timeDetails);
        templateOptions.hide(campaignContract.place);
      } else {
        campaign = update.set(
          campaign,
          campaignContract.allowTimeAndPlace,
          isTimeAndPlaceEnabled
        );

        if (date && moment(date).isValid()) {
          campaign = update.set(
            campaign,
            campaignContract.date,
            moment(date).format(config.DEFAULT_DATE_FORMAT)
          );
          campaign = update.set(
            campaign,
            campaignContract.time,
            includesTime
              ? moment(date).format(config.DEFAULT_TIME_FORMAT)
              : null
          );
        }
        if (end && moment(end).isValid()) {
          campaign = update.set(
            campaign,
            campaignContract.endDate,
            moment(end).format(config.DEFAULT_DATE_FORMAT)
          );
          campaign = update.set(
            campaign,
            campaignContract.endTime,
            includesTime
              ? moment(end).format(config.DEFAULT_TIME_FORMAT)
              : null
          );
        }
      }

      //qr codes
      const unbdrandedQrCode = get(
        campaign,
        'template.value.customSetup.unbrandedQrCode'
      );
      const campaignType = get(campaign, 'type');

      if (campaignType.toLowerCase() !== campaignTypes.giveNow) {
        templateOptions.show(campaignContract.qrCodesHeader);
        templateOptions.show(campaignContract.qrCodesContent);
        templateOptions.show(campaignContract.qrCodesCheckbox);
        templateOptions.show(campaignContract.qrCodesButtons);
      }

      if (!unbdrandedQrCode) {
        campaign = update.set(campaign, 'qrCodesCheckbox', true);
      }

      //custom activities
      const customActivitiesEnabled = get(
        campaign,
        campaignContract.customActivitiesEnable
      );
      if (!customActivitiesEnabled) {
        templateOptions.hide(
          campaignContract.customActivitiesValue1LeaderBoardEnabled
        );
        templateOptions.hide(
          campaignContract.customActivitiesValue1ThermometerEnabled
        );

        templateOptions.hide(campaignContract.customActivitiesValue1Name);
        templateOptions.hide(
          campaignContract.customActivitiesValue1CampaignTargetField
        );
        templateOptions.hide(
          campaignContract.customActivitiesValue1FundraiserDefaultTargetField
        );
        templateOptions.hide(
          campaignContract.customActivitiesValue1TeamDefaultTargetField
        );
        templateOptions.hide(campaignContract.customActivitiesValue1Svg);
        templateOptions.hide(campaignContract.customActivitiesValue1Unit);
      }

      const customActivitiesValue1ThermometerEnabled = get(
        campaign,
        campaignContract.customActivitiesValue1ThermometerEnabled
      );
      if (
        customActivitiesEnabled &&
        !customActivitiesValue1ThermometerEnabled
      ) {
        templateOptions.hide(
          campaignContract.customActivitiesValue1CampaignTargetField
        );
        templateOptions.hide(
          campaignContract.customActivitiesValue1FundraiserDefaultTargetField
        );
        templateOptions.hide(
          campaignContract.customActivitiesValue1TeamDefaultTargetField
        );
        templateOptions.hide(campaignContract.customActivitiesValue1Svg);
      }

      //custom extra fields
      const customExtraFields = get(
        campaign,
        'template.value.customExtraFields'
      );

      if (isArrayWithItems(customExtraFields)) {
        const coordinatorFirstName = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorFirstName
        );
        if (!coordinatorFirstName) {
          templateOptions.hide(campaignContract.coordinatorFirstName);
        }

        const coordinatorLastName = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorLastName
        );
        if (!coordinatorLastName) {
          templateOptions.hide(campaignContract.coordinatorLastName);
        }

        const coordinatorEmail = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorEmail
        );
        if (!coordinatorEmail) {
          templateOptions.hide(campaignContract.coordinatorEmail);
        }

        const coordinatorPhone = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorPhone
        );
        if (!coordinatorPhone) {
          templateOptions.hide(campaignContract.coordinatorPhone);
        }
      } else {
        templateOptions.hide(campaignContract.coordinatorFirstName);
        templateOptions.hide(campaignContract.coordinatorLastName);
        templateOptions.hide(campaignContract.coordinatorEmail);
        templateOptions.hide(campaignContract.coordinatorPhone);
      }

      // prettier-ignore
      if (this.props.isSystemAdmin && !!templateOptions.getValue(campaignContract.allowCustomUrlCheckbox)) {
        templateOptions.show(campaignContract.customUrlCheckboxPath);
        // prettier-ignore
        const urlPathEnabled = get(campaign, campaignContract.customUrlCheckboxPath);
        if (urlPathEnabled === undefined) {
          // prettier-ignore
          campaign = update.set(campaign, campaignContract.customUrlCheckboxPath, true);
          templateOptions.hide(campaignContract.customUrlPath);
        } else if (!urlPathEnabled) {
          // if use campaign url un-ticked, show URL Path
          templateOptions.show(campaignContract.customUrlPath);
        }
      }

      if (this.props?.record?.data?.data?.ticketing?.installments?.enabled) {
        templateOptions.show(campaignContract.installmentCountOptions);
        templateOptions.show(campaignContract.ticketInstallmentFrequency);
        templateOptions.show(campaignContract.ticketInstallmentNewSalesUntil);
      } else {
        templateOptions.hide(campaignContract.installmentCountOptions);
        templateOptions.hide(campaignContract.ticketInstallmentFrequency);
        templateOptions.hide(campaignContract.ticketInstallmentNewSalesUntil);
      }

      // donation setup
      const donationSetup = campaign?.template?.value?.donationSetup;
      const hasReturnUrl = donationSetup?.hasOwnProperty('returnUrl');
      templateOptions.setVisible(campaignContract.returnUrl, hasReturnUrl);

      //invertion colours
      const invertPrimaryTextColor = get(
        campaign,
        campaignContract.invertPrimaryTextColor
      );
      const invertSecondaryTextColor = get(
        campaign,
        campaignContract.invertSecondaryTextColor
      );
      if (!invertPrimaryTextColor) {
        campaign = update.set(
          campaign,
          campaignContract.invertPrimaryTextColor,
          false
        );
      }
      if (!invertSecondaryTextColor) {
        campaign = update.set(
          campaign,
          campaignContract.invertSecondaryTextColor,
          false
        );
      }

      //needed only for old campaigns which don't have microblogs (temporary solution for bug #2797)
      const allowMicroblogsVisible = getState(
        templateOptions.state,
        campaignContract.allowMicroblogs,
        'visible'
      );
      campaign = update.set(
        campaign,
        campaignContract.allowMicroblogs,
        allowMicroblogsVisible === false
          ? false
          : get(campaign, campaignContract.allowMicroblogs)
      );

      templateOptions.setModel(campaign);
      templateOptions.isEdit();
      templateOptions.hide('organizationId');

      this.props.formBuilderActions.setFormBuilderModel(donationsFormBuilder);
      this.props.formBuilderActions.setFormBuilderModel(fundraisersFormBuilder);
      this.props.formBuilderActions.setFormBuilderModel(salesFormBuilder);
      this.props.setTemplateModel(templateOptions.getWithRule());
    }

    const isLoading =
      RecordHelper.isRecordLoading(this.props.recordEdit) ||
      RecordHelper.isRecordLoading(this.props.recordComplete);
    const dropdownOptions = Object.keys(campaignStatuses).map((status) => ({
      text: campaignStatuses[status].description,
      value: status.toLowerCase()
    }));
    const isStatusLoading = RecordHelper.isRecordLoading(
      this.props.statusRecord
    );
    const confirmModalContent = this.getConfirmModalContent();
    const errorModalContent = this.getErrorModalContent();
    const currentStatus = this.getCampaignStatus();
    const successModalRedirectButtons = this.getSavedModalButtons();
    const successModalContent = this.getSavedModalContent();

    return (
      <BasePage>
        {!isLoading && (
          <RecordResultMessage
            record={this.props.recordEdit}
            onDismiss={this.clearMessages}
          />
        )}

        <RecordResultMessage
          record={this.props.statusRecord}
          onDismiss={this.clearMessages}
        />

        <SavedEntityModal
          open={this.props.saveModalOpen}
          content={successModalContent}
          buttons={successModalRedirectButtons}
          onClose={this.onModalClose}
          isEdit
        />

        <ConfirmationModal
          open={this.state.isConfirmModalOpened}
          content={confirmModalContent}
          onClose={this.onModalClose}
          onSubmit={() => this.updateCampaignStatus()}
        />

        <EntityModal
          open={this.state.isErrorModalOpened}
          title="Warning"
          onClose={this.onModalClose}
        >
          {errorModalContent}
        </EntityModal>

        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <h1>
                <Translate value="campaign.edit.title" />
                {campaign.name}
              </h1>
              {I18n.t(
                `campaign.create.list.${campaign.type.toLowerCase()}.title`
              )}
            </Grid.Column>
            <Grid.Column width={2}>
              {!this.props.isCampaignAdmin && !this.props.isEventAdmin && (
                <Dropdown
                  id="campaign-statuses"
                  selection
                  options={dropdownOptions}
                  value={currentStatus}
                  onChange={this.onDropdownItemChange.bind(this)}
                  loading={isStatusLoading}
                  icon="chevron down"
                ></Dropdown>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CampaignTemplate
          onSave={this.onSave.bind(this)}
          isLoading={isLoading}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, templateBuilderEx, session }) => {
  const templates = campaign.templates;
  const record = campaign.record || {};
  const ticketsRecord = campaign.ticketsRecord || {};
  const couponsRecord = campaign.couponsRecord || {};
  const { totalFundraisersRecord, totalTeamsRecord, totalCollectionsRecord } =
    campaign;

  const isEventAdmin = session.isEventAdmin;
  const isCampaignAdmin = session.isCampaignAdmin;
  const isSystemAdmin = session.isSystemAdmin;

  return {
    templates,
    record,
    ticketsRecord,
    couponsRecord,
    totalFundraisersRecord,
    totalTeamsRecord,
    totalCollectionsRecord,
    saveModalOpen: campaign.saveModalOpen,
    campaignRequestErrors: campaign.campaignRequestErrors,
    recordComplete: campaign.recordComplete,
    recordEdit: campaign.recordEdit,
    statusRecord: campaign.statusRecord,
    isTemplateInited: templateBuilderEx.inited,
    isSystemAdmin: session.isSystemAdmin,
    isCampaignAdmin,
    isEventAdmin,
    isSystemAdmin
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(campaignActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch),
    ...bindActionCreators(generalActions, dispatch),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

/** Connects component to Redux store */
const EditCampaignContainer = clear(
  connect(mapState, mapDispatch)(CampaignEdit)
);
export default EditCampaignContainer;
