import update from '../../helpers/update';
import * as entityHandlers from '../../modules/bin/entityHandlers';
import { listReadyStateClear } from '../../helpers/donationHelper';
import { Record } from '../../modules/bin/utility';

import {
  getRecurringGrid,
  getRecurringDonationsGrid
} from './gridConfiguration';
import { gridKeys } from '../../constants/gridKeys';

import { LIST_READY_STATE_CLEAR, CLEAR } from '../../modules/general';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS,
  IMPERSONATE_ADMIN_SUCCESS
} from '../../modules/session';
import { recurringContract, donationRecurringFrequency } from './constants';
import config from '../../constants';

export const METADATA_REQUESTED = 'recurring/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'recurring/METADATA_SUCCESS';
export const METADATA_FAILURE = 'recurring/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'recurring/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'recurring/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'recurring/LIST_DATA_FAILURE';

export const GET_EXPORT_FILE_REQUESTED = 'recurring/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'recurring/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'recurring/GET_EXPORT_FILE_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'recurring/TOGGLE_COLUMNS_CHANGE';

export const ERROR_CLEAR = 'recurring/ERROR_CLEAR';

export const CREATE_REQUESTED = 'recurring/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'recurring/CREATE_SUCCESS';
export const CREATE_FAILURE = 'recurring/CREATE_FAILURE';

export const UPDATE_REQUESTED = 'recurring/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'recurring/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'recurring/UPDATE_FAILURE';

export const GET_REQUESTED = 'recurring/GET_REQUESTED';
export const GET_SUCCESS = 'recurring/GET_SUCCESS';
export const GET_FAILURE = 'recurring/GET_FAILURE';

export const REFUND_CONFIRMATION_SHOW = 'recurring/REFUND_CONFIRMATION_SHOW';
export const REFUND_CONFIRMATION_HIDE = 'recurring/REFUND_CONFIRMATION_HIDE';
export const CANCEL_CONFIRMATION_SHOW = 'recurring/CANCEL_CONFIRMATION_SHOW';
export const CANCEL_CONFIRMATION_HIDE = 'recurring/CANCEL_CONFIRMATION_HIDE';

export const IS_REFUNDED_CLEAR = 'recurring/IS_REFUNDED_CLEAR';
export const IS_CANCELLED_CLEAR = 'recurring/IS_CANCELLED_CLEAR';

export const REFUND_REQUESTED = 'recurring/REFUND_REQUESTED';
export const REFUND_SUCCESS = 'recurring/REFUND_SUCCESS';
export const REFUND_FAILURE = 'recurring/REFUND_FAILURE';

export const CANCEL_REQUESTED = 'recurring/CANCEL_REQUESTED';
export const CANCEL_SUCCESS = 'recurring/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'recurring/CANCEL_FAILURE';

export const FETCH_PAYMENT_FEE_STARTED = 'recurring/FETCH_PAYMENT_FEE_STARTED';
export const FETCH_PAYMENT_FEE_SUCCESS = 'recurring/FETCH_PAYMENT_FEE_SUCCESS';
export const FETCH_PAYMENT_FEE_FAILURE = 'recurring/FETCH_PAYMENT_FEE_FAILURE';

export const CHANGE_MY_URL_MODAL_VISIBILITY =
  'recurring/CHANGE_MY_URL_MODAL_VISIBILITY';

const i18nListKey = 'recurring.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  record: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  paymentFee: Record.getDefaultState(),
  [gridKeys.RECURRING]: getRecurringGrid(i18nListKey),
  [gridKeys.RECURRING_DONATIONS]: getRecurringDonationsGrid(i18nListKey),
  showMyUrlModal: false
};

// State Reducer
export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS:
      newState = initialState;
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.RECURRING);
      newState = listReadyStateClear(newState, gridKeys.RECURRING_DONATIONS);
      break;

    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
    case REFUND_REQUESTED:
    case CANCEL_REQUESTED:
    case FETCH_PAYMENT_FEE_STARTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
    case FETCH_PAYMENT_FEE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
    case FETCH_PAYMENT_FEE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case REFUND_CONFIRMATION_SHOW:
      newState = update.set(newState, 'refundDonation', action.payload);
      newState = update.set(newState, 'refundConfirmation', true);
      break;

    case REFUND_CONFIRMATION_HIDE:
      newState = update.set(newState, 'refundConfirmation', false);
      break;

    case REFUND_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'isRefunded', true);
      newState = update.set(newState, 'refundConfirmation', false);
      break;

    case REFUND_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'refundConfirmation', false);
      break;

    case IS_REFUNDED_CLEAR:
      newState = update.set(newState, 'isRefunded', false);
      break;

    case CANCEL_CONFIRMATION_SHOW:
      newState = update.set(newState, 'cancelDonation', action.payload);
      newState = update.set(newState, 'cancelConfirmation', true);
      break;

    case CANCEL_CONFIRMATION_HIDE:
      newState = update.set(newState, 'cancelConfirmation', false);
      break;

    case CANCEL_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'isCancelled', true);
      newState = update.set(newState, 'cancelConfirmation', false);
      break;

    case CANCEL_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'cancelConfirmation', false);
      break;

    case IS_CANCELLED_CLEAR:
      newState = update.set(newState, 'isCancelled', false);
      break;

    case CHANGE_MY_URL_MODAL_VISIBILITY: {
      newState = update.set(newState, 'showMyUrlModal', action.payload.value);
      break;
    }

    default:
      break;
  }
  return newState;
};

export const clearRecordMessages = (record) => ({
  type: ERROR_CLEAR,
  payload: { key: record }
});

export const getMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'donationSubscription',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'donationSubscription',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getListDataBySearchTerm = (listKey, id, request, searchTerm) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getListData(listKey, id, newRequest, true);
};

export const getDonationsMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'donationSubscriptionPayment',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getDonationsListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'donationSubscriptionPayment',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getDonationsListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getDonationsListData(listKey, id, newRequest, true);
};

export const toggleColumnsChange = (listKey, toggleColumns) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );
// export const getExportFile = (entity, request, fileName) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName
//   );
// };

export const getRecurringDonationRecord = (id) =>
  entityHandlers.getRecord(
    'donationSubscription',
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    id
  );

export const createRecurringDonation = (record) =>
  entityHandlers.createRecord(
    'donationSubscription',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record,
    (state, data) => {
      data = update.unset(data, 'accountKey');
      data = update.unset(data, 'primaryPath');

      if (config.node_env === 'development') {
        data = update.set(
          data,
          recurringContract.frequency,
          donationRecurringFrequency.daily
        );
      }

      return data;
    },
    'recordCreate'
  );

export const updateRecurringDonation = (data) =>
  entityHandlers.updateRecord(
    'donationSubscription',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    data.id,
    data,
    (state, data) => {
      data = update.unset(data, 'accountKey');
      data = update.unset(data, 'primaryPath');

      if (data.payment && (data.payment.empty || !data.payment.complete)) {
        data = update.unset(data, 'payment');
      }
      return data;
    },
    'recordEdit'
  );

export const showRefundConfirmation = (item) => {
  return { type: REFUND_CONFIRMATION_SHOW, payload: item };
};

export const closeRefundConfirmation = () => {
  return { type: REFUND_CONFIRMATION_HIDE };
};

export const refundDonation = (id, verificationToken) => {
  return entityHandlers.deleteRecord(
    `donationSubscriptionPayment`,
    REFUND_REQUESTED,
    REFUND_SUCCESS,
    REFUND_FAILURE,
    id,
    'saveRecord',
    verificationToken || null,
  );
};

export const clearIsRefunded = () => ({ type: IS_REFUNDED_CLEAR });

export const showCancelConfirmation = (item) => {
  return { type: CANCEL_CONFIRMATION_SHOW, payload: item };
};

export const closeCancelConfirmation = () => {
  return { type: CANCEL_CONFIRMATION_HIDE };
};

export const cancelDonation = (id) => {
  return entityHandlers.deleteRecord(
    `donationSubscription`,
    CANCEL_REQUESTED,
    CANCEL_SUCCESS,
    CANCEL_FAILURE,
    id,
    'saveRecord'
  );
};

export const clearIsCancelled = () => ({ type: IS_CANCELLED_CLEAR });

export const recalculatePaymentFee = (orgPath, amount) => {
  const requestUrl = `${orgPath}?amount=${amount}&coverFee=true`;
  return entityHandlers.getRecord(
    'donationSubscription/fee',
    FETCH_PAYMENT_FEE_STARTED,
    FETCH_PAYMENT_FEE_SUCCESS,
    FETCH_PAYMENT_FEE_FAILURE,
    requestUrl,
    'paymentFee'
  );
};

export const changeMyURLModalVisibility = (value) => {
  return {
    type: CHANGE_MY_URL_MODAL_VISIBILITY,
    payload: {
      value
    }
  };
};
