//-------React/Redux
import React from 'react';

//-------Components
import { Icon } from 'semantic-ui-react';

export const AmountItem = (props) => {
  const formatAmount = () => {
    const type = props.type || 'fundraisingTarget';
    if (type === 'fitnessTarget') {
      return `${props.label} ${props.fitnessUnit}`;
    }

    return `${props.currency} ${props.label}`;
  };

  return (
    <div className="amount-item-container">
      <label className="amount-item-title">
        {formatAmount()}
        <Icon
          name="remove circle"
          size="large"
          onClick={() => props.onDelete(props.index)}
        />
      </label>
    </div>
  );
};
