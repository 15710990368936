import React, { useMemo, useEffect } from 'react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';
import './index.css';
import DatePickerInput from '../form/datePicker';
import config from "../../constants";
import moment from 'moment';

const FormbuilderDatePicker = (props) => {
  const {
    value,
    placeholder,
    label,
    validation,
    error,
    errorMessage,
    onBlur,
    inputSub,
    popup,
    onChange,
    initialValidation
  } = props;

  const formattedValue = useMemo(() =>
    moment(value).format(config.DEFAULT_DATE_FORMAT),
    [value]
  );

  useEffect(() => {
    if (!value) {
      onChange(formattedValue);
    }
  }, [value]);

  return (
    <FormbuilderFieldWrapper
      label={label}
      validation={validation}
      error={error}
      errorMessage={errorMessage}
      onBlur={onBlur}
      inputSub={inputSub}
      popup={popup}
      value={formattedValue}
      initialValidation={initialValidation}
    >
      <DatePickerInput
        id="formbuilder-datepicker"
        onChange={onChange}
        value={formattedValue}
        placeholder={placeholder}
      />
    </FormbuilderFieldWrapper>
  );
}

export default FormbuilderDatePicker;
