import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';

import * as templateBuilderActions from '../../modules/template-builder-ex';
import * as templateHelper from '../../components/template/templateHelper';
import * as recurringDonationActions from './reducer';
import { Record as RecordHelper } from '../../modules/bin/utility';

import { Form } from 'semantic-ui-react';
import { TemplatePage } from '../../components/common/pager';
import { TemplateHeader } from '../../components/template/templateHeader';
import { TemplateInput } from '../../components/template/templateInput';
import { TemplateAutocomplete } from '../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../components/template/templateCurrency';
import { TemplateDropdown } from '../../components/template/templateDropdown';
import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';
import TemplateCard from '../../components/template/templateCard';
import CoverFee from '../../components/common/donateElements/coverFee';
import { TemplateDynamicText } from '../../components/template/templateDynamicText';

import CurrencyHelper from '../../helpers/currencyHelper';

import {
  recurringContract,
  donationRecurringFrequencyOptions,
  donationCountBottomLimit,
  donationCountLimitStep,
  donationCountTopLimit
} from './constants';
import { AMOUNT_VALIDATION } from '../../constants/validationModels';
import config from '../../constants';

import { useDonationSubscriptionSettings } from '../../components/template/useDonationSubscriptionSettings';

class RecurringDonationTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.emailValidation = {
      required: true,
      email: true
    };

    this.amount = {
      required: true,
      ...AMOUNT_VALIDATION
    };

    this.card = {
      required: true,
      card: true
    };
  }

  onChange = (path, value) => {
    this.props.setResultValue(path, value);
  };

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getOrganizationItem = (item) => {
    if (item.paymentPlatforms && item.paymentPlatforms.length === 0) {
      return;
    }

    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  getDonationLimitCountOptions = () => {
    let options = [
      {
        value: null,
        text: 'No time limit'
      }
    ];
    for (
      let i = donationCountBottomLimit;
      i < donationCountTopLimit;
      i += donationCountLimitStep
    ) {
      options.push({
        value: i,
        text: `Stop after ${i} payments`
      });
    }

    return options;
  };

  getDonationRecurringFrequencyOptions = (isSystemAdmin = false) => {
    return donationRecurringFrequencyOptions(isSystemAdmin)
      .filter((item) => {
        return !item.devMode || config.node_env === 'development';
      })
      .map((item) => {
        return {
          value: item.value,
          text: item.text
        };
      });
  };

  onOrganizationChange = (path, value, item) => {
    if (item) {
      this.props.setResultValue('primaryPath', item.urlPath);
      this.props.setResultValue('currency', item.currency);

      if (item.paymentPlatforms && item.paymentPlatforms.length !== 0) {
        this.props.setResultValue(
          'accountKey',
          item.paymentPlatforms[0].accountKey
        );
      }
    }
    this.props.setResultValue(path, value);
  };

  makePayment = (data) => {
    if (this.props.isEdit) {
      this.props.updateRecurringDonation(data);
    } else {
      this.props.createRecurringDonation(data);
    }
  };

  onCoverFeeChange = (path, value) => {
    this.props.changeItemVisibility('amountWithFee', value, false);
    this.props.setResultValue(path, value);
  };

  render() {
    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage
          id="recurring-detail"
          i18nKey="template.recurring-donation.content.detail.header"
        >
          <TemplateHeader
            as="h3"
            label="template.recurring-donation.content.detail.header"
          />

          <TemplateAutocomplete
            id={recurringContract.organization}
            entity="organization"
            path={recurringContract.organization}
            selected="organization"
            label="template.recurring-donation.content.detail.organization.label"
            placeholder="template.recurring-donation.content.detail.organization.placeholder"
            validation={this.required}
            onChange={this.onOrganizationChange}
            request={this.getOrganizationRequest}
            getItem={this.getOrganizationItem}
          />

          <TemplateInput
            id={recurringContract.firstName}
            path={recurringContract.firstName}
            label="template.recurring-donation.content.detail.firstName.label"
            placeholder="template.recurring-donation.content.detail.firstName.placeholder"
            onChange={this.onChange}
            validation={this.required}
          />

          <TemplateInput
            id={recurringContract.lastName}
            path={recurringContract.lastName}
            label="template.recurring-donation.content.detail.lastName.label"
            placeholder="template.recurring-donation.content.detail.lastName.placeholder"
            onChange={this.onChange}
            validation={this.required}
          />

          <TemplateInput
            id={recurringContract.email}
            path={recurringContract.email}
            label="template.recurring-donation.content.detail.emailAddress.label"
            placeholder="template.recurring-donation.content.detail.emailAddress.placeholder"
            onChange={this.onChange}
            validation={this.emailValidation}
          />
        </TemplatePage>

        <TemplatePage
          id="amount-and-frequency"
          path="amount-and-frequency"
          i18nKey="template.recurring-donation.content.amount-and-frequency.header"
        >
          <TemplateHeader
            as="h3"
            label="template.recurring-donation.content.amount-and-frequency.header"
          />

          {/* TODO: CREATE SEPARATE COMPONENT */}

          <Form.Group
            widths="equal"
            validation={this.amount}
            path={recurringContract.amount}
          >
            <TemplateCurrency
              id={recurringContract.amount}
              path={recurringContract.amount}
              label="template.recurring-donation.content.amount-and-frequency.amount.label"
              allowDecimal
              onChange={this.onChange}
              validation={this.amount}
              currency={this.props.currency}
            />

            <TemplateDynamicText
              className="vertical-align-field"
              path="amountWithFee"
              loading={this.props.isPaymentFeeLoading}
              value={this.props.amountWithFee}
              label="template.recurring-donation.content.amount-and-frequency.amountWithFee.label"
            />
          </Form.Group>

          <CoverFee
            id={recurringContract.coverFee}
            path={recurringContract.coverFee}
            inputLabel="template.recurring-donation.content.amount-and-frequency.coverFee.label"
            amount={this.props.amount}
            primaryPath={this.props.primaryPath}
            onChange={this.onCoverFeeChange}
            recalculatePaymentFee={this.props.recalculatePaymentFee}
          />

          {/* END OF TODO */}

          <TemplateDropdown
            id={recurringContract.frequency}
            path={recurringContract.frequency}
            label="template.recurring-donation.content.amount-and-frequency.frequency.label"
            onChange={this.onChange}
            options={this.getDonationRecurringFrequencyOptions(this.props.isSystemAdmin)}
          />

          <TemplateDropdown
            id={recurringContract.donationCountLimit}
            path={recurringContract.donationCountLimit}
            defaultLabel="template.recurring-donation.content.amount-and-frequency.donationCountLimit.defaultLabel"
            label="template.recurring-donation.content.amount-and-frequency.donationCountLimit.label"
            onChange={this.onChange}
            options={this.getDonationLimitCountOptions()}
          />
        </TemplatePage>

        <TemplatePage
          id="behaviour"
          path="behaviour"
          i18nKey="template.recurring-donation.content.behaviour.header"
        >
          <TemplateHeader
            as="h3"
            label="template.recurring-donation.content.behaviour.header"
          />

          <TemplateDropdown
            id="settings"
            path="settings"
            label="template.organisation.content.recurring-giving.receipt.label"
            subtext="template.organisation.content.recurring-giving.receipt.subtext"
            {...useDonationSubscriptionSettings({
              value: this.props.settings,
              onChange: this.onChange
            })}
          />
        </TemplatePage>

        <TemplatePage
          id="payment"
          path="payment"
          i18nKey="template.recurring-donation.content.payment.header"
        >
          <TemplateHeader
            as="h3"
            label="template.recurring-donation.content.payment.header"
          />

          <TemplateCard
            label="template.recurring-donation.content.payment.card.label"
            accountKey={this.props.accountKey}
            donor={this.props.donor}
            path={recurringContract.payment}
            validation={this.props.isEdit ? {} : this.card}
            onChange={this.onChange}
            makePayment={this.makePayment}
          />
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  const paymentFeeRecord = state.recurring.paymentFee;
  const isPaymentFeeLoading = RecordHelper.isRecordLoading(paymentFeeRecord);
  const isSystemAdmin = get(state, 'session.isSystemAdmin');
  const currency = templateHelper.getValue(state.templateBuilderEx, 'currency');
  const amountNofeeData = get(paymentFeeRecord, 'data.data.totalWithFees');
  const amountWithFee = isNaN(amountNofeeData) ? null : CurrencyHelper.formatCurrency(amountNofeeData, currency);
  return {
    isEdit: state.templateBuilderEx.isEdit,
    accountKey: templateHelper.getValue(state.templateBuilderEx, 'accountKey'),
    currency,
    donor: templateHelper.getValue(state.templateBuilderEx, 'donor'),
    amount: templateHelper.getValue(
      state.templateBuilderEx,
      recurringContract.amount
    ),
    primaryPath: templateHelper.getValue(
      state.templateBuilderEx,
      'primaryPath'
    ),
    isPaymentFeeLoading,
    amountWithFee,
    isSystemAdmin,
    settings: templateHelper.getValue(state.templateBuilderEx, 'settings')
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    ...bindActionCreators(recurringDonationActions, dispatch)
  };
};

const RecurringDonationTemplate = withRouter(
  connect(mapState, mapDispatch)(RecurringDonationTemplateView)
);
export default RecurringDonationTemplate;
