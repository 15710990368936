// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as recurringActions from './reducer';

// Helpers
import get from 'lodash.get';
import { getListWithOrgFilterIfSysAdmin } from '../../helpers/listHelper';
import { hideCondition } from '../../helpers/hideHelper';
import clear from '../../components/clear';
import { Record } from '../../modules/bin/utility';

//css
import './index.css';

// Components
import { Common } from '../../components';
import Spinner from '../../components/common/spinner';
import BasePage from '../../components/common/basePageView';
import Button from '../../components/common/button';
import { Menu } from 'semantic-ui-react';
import RecordResultMessage from '../../components/common/recordResult';
import CancelWindowContainer from './close-window';
import RecurringPanel from './panel';
import { ExportDownloadProvider } from '../export-history';

// Constants
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { gridKeys } from '../../constants/gridKeys';

// ----------------------------------------------------------------------

/** A page to view all recurring donations in a list */
class RecurringList extends Component {
  clearMessages = () => {
    this.props.actionGrid.clearRecordMessages('recordEdit');
    this.props.actionGrid.clearRecordMessages('recordCreate');
  };

  showSpinner = () => {
    return (
      this.props.isSessionLoading ||
      Record.isRecordLoading(this.props.exportFileRecord)
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.list) ||
      Record.isRecordLoading(this.props.list.metadata)
    );
  };

  onAddRecurringDonation = () => {
    this.props.history.push('/recurring/create/recurring-detail');
  };

  isEditButtonHidden = (item) => {
    return item.status === 'cancelled';
  };

  isCancelButtonHidden = (item) => {
    return item.status === 'cancelled';
  };

  onCancelContextClick = (id, item) => {
    this.props.showCancelConfirmation(item);
  };

  /**
   * Renders the component
   */
  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;
    const filterButtons = get(this.props, 'list.filterButtons');

    return (
      <BasePage>
        <div className="grid-top-block">
          <h1>
            <Translate value="recurring.title" />
          </h1>
          <RecurringPanel />
        </div>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
        />
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
        />

        <CancelWindowContainer />

        <div>
          <Menu
            className="accounts-tab-buttons-group"
            style={{ margin: '0px', borderRight: '0px' }}
          >
            {Object.keys(filterButtons).map((key) => {
              const item = filterButtons[key];
              return (
                <Menu.Item
                  key={item.key}
                  name={item.key}
                  content={I18n.t(item.I18nKey)}
                  active={item.key === this.props.match.params.tab}
                  disabled={this.isChooseTabButtonDisabled()}
                  onClick={() => {
                    this.props.history.push(`/recurring/${item.key}`);
                  }}
                />
              );
            })}
          </Menu>
          <Grid.Managed
            listKey={this.props.listKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.list,
              this.props.isSystemAdmin
            )}
            actions={this.props.actionGrid}
            toggleColumns
            search
            hideColumn={this.hideColumnCondition}
            activeTabFilter={this.props.match.params.tab}
            filtersButton
          >
            <Panel name="right">
              {this.props.isExportDataAllowed && (
                <ExportDownloadProvider
                  entity="donationSubscription"
                  version="v3"
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open(get(this.props, 'list.request'))}
                    >
                      <Translate value="recurring.button.export" />
                    </Button>
                  )}
                </ExportDownloadProvider>
              )}
              {/*
              <Button onClick={this.onAddRecurringDonation}>
                <Translate value="recurring.button.new-recurring-donation" />
              </Button>
              */}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.RECURRING.VIEW.key}
                label={I18n.t('recurring.list.context.view')}
                contextMenuOptions={CONTEXT_MENU_KEYS.RECURRING.VIEW}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.RECURRING.EDIT.key}
                label={I18n.t('recurring.list.context.edit')}
                contextMenuOptions={CONTEXT_MENU_KEYS.RECURRING.EDIT}
                isHiden={this.isEditButtonHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.RECURRING.CANCEL_DONATION.key}
                label={I18n.t('recurring.list.context.cancel')}
                onClick={this.onCancelContextClick}
                isHiden={this.isCancelButtonHidden}
              />
            </ContextMenu>
          </Grid.Managed>
        </div>
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ recurring, session }) => {
  const { recordEdit, recordCreate, exportFileRecord } = recurring;

  // A reference to the list in state
  const stateGrid = Common.Grid.Managed.mapGridState(
    recurring,
    gridKeys.RECURRING
  );

  return {
    recordEdit,
    recordCreate,
    exportFileRecord,
    listKey: stateGrid.key,
    list: stateGrid.list,
    organization: session.organization,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    isSystemAdmin: get(session, 'isSystemAdmin'),
    isSessionLoading: get(session, 'processing'),
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  const actionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    recurringActions
  );
  return {
    actionGrid,
    ...bindActionCreators(recurringActions, dispatch)
  };
};

/** Connects component to Redux store */
const RecurringPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(RecurringList))
);
export default RecurringPageContainer;
