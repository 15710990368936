import React, { useState, useEffect, useCallback } from 'react';

import * as ticketingActions from '../../reducer';
import { CurrencyInput } from '../../../../components/common/input/CurrencyInput';
import { useAppDispatch } from '../../../../hooks';
import { AppDispatch, RootState } from '../../../../store';
import { refundSaleValues } from '../../constants';
import { useSelector } from 'react-redux';
import { grecaptcha } from '../../../../helpers/grecaptcha';

interface ICustomRefundProps {
  selectedOption: string;
  maxValue: number;
  minValue: number;
  setIsRefundOptionValid: (value: boolean) => void;
  setOnRefundCallback: (func: () => void) => void;
}

export const CustomRefund = ({
  selectedOption,
  maxValue,
  minValue,
  setIsRefundOptionValid,
  setOnRefundCallback
}: ICustomRefundProps) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const dispatch: AppDispatch = useAppDispatch();
  // prettier-ignore
  const { sale: { refundRecord} } = ticketing;
  const [refundAmount, setRefundAmount] = useState<number>(0);

  // prettier-ignore
  const onRefundClick = useCallback(() => {
    return () => {
      const tokenCallback = (token: string) => {
        if (selectedOption === refundSaleValues.CANCELLED_AND_REFUNDED) {
          dispatch(ticketingActions.refundAndCancelSale(refundRecord.id, refundAmount, token));
        }
        else if (selectedOption === refundSaleValues.CANCELLED) {
          dispatch(ticketingActions.refundAndCancelSale(`${refundRecord.id}?refund=false`), undefined, token);
        }
        else if (selectedOption === refundSaleValues.REFUNDED) {
          dispatch(ticketingActions.refundSale(refundRecord.id, refundAmount, token));
        }
      }
      grecaptcha(tokenCallback, "SaleUpdate");
    }
  }, [selectedOption, refundAmount]);

  useEffect(() => {
    setRefundAmount(maxValue);
    setIsRefundOptionValid(maxValue >= minValue);
  }, [maxValue]);

  useEffect(() => {
    setOnRefundCallback(onRefundClick);
  }, [selectedOption, refundAmount]);

  return (
    <>
      <p
        style={{ margin: 0 }}
      >{`Amount to refund (Maximum refund $${maxValue.toFixed(2)})`}</p>
      <CurrencyInput
        maxValue={maxValue}
        minValue={minValue}
        value={refundAmount}
        setValue={(num: number) => {
          setIsRefundOptionValid(num >= minValue);
          setRefundAmount(num);
        }}
      />
    </>
  );
};
