import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Form, Message } from "semantic-ui-react";
import axios from "axios";
import get from "lodash.get";

import Card from "./card";

import { cardActions } from "../../../modules/card";
import constants from "../../../constants";

export const MakePaymentForm = (props) => {
  const {
    required,
    path,
    onChange: handleChange,
    makePayment,
  } = props;

  const session = useSelector(state => get(state, "session"));
  const templateBuilder = useSelector(state => get(state, "templateBuilderEx"));
  const serverErrorOccured = useSelector((state) => get(state.card, `${path}.serverErrorOccured`));
  const needPaymentData = useSelector((state) => get(state.card, `${path}.needPaymentData`));
  const recurringRecord = useSelector((state) => get(state, "recurring.record"));

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  useEffect(() => {
    if (needPaymentData && stripe && elements) confirmPayment();
  }, [needPaymentData, stripe, elements]);

  const confirmPayment = async () => {
    dispatch(cardActions.setPaymentStarted(path));

    const intent = await axios.put(
      `${constants.baseApiHost}/api/v2/donationSubscription/payment/method/change`,
      {
        id: get(recurringRecord, "data.data.id"),
        paymentMethod: "creditCard",
        paymentType: "standard",
      },
      {
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${session.key}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (!intent || get(intent, "data.error")) {
      dispatch(cardActions.makePaymentErrorOccured(get(intent, "data.error"), path));
    } else {
      let result = await stripe.confirmCardSetup(
        get(intent, "data.data.financials.intentPublicKey"),
        { payment_method: { card: elements.getElement(CardNumberElement) }, }
      );

      if (result.error) {
        dispatch(cardActions.makePaymentErrorOccured(result.error, path));
      } else {
        dispatch(cardActions.setPaymentSuccess(path));
        
        if (makePayment) makePayment(templateBuilder.data);
      };
    }
  };

  return (
    <Form>
      <Form.Field required={required}>
        <Card
          path={path}
          onChange={handleChange}
        />
      </Form.Field>
      {serverErrorOccured && (
        <Message negative>
          <Message.Header>
            There was a problem with processing your transaction
          </Message.Header>
          <p className="error">
            Please check your information or reload the page and try again
          </p>
        </Message>
      )}
    </Form>
  );
};

export default MakePaymentForm;

