/* eslint-disable no-template-curly-in-string */
import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import {
  getCampaignClickableColumnUrl,
  getTeamClickableColumnUrl,
  getCampaignClickableColumnForCampaignFundraisers,
  getFundraiserClickableColumnUrl,
  getCollectionClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';
import {
  campaignAvailableFiltersKeys,
  campaignDonationsAvailableFilters,
  PRIVACY_COLUMN,
  campaignFundraisingAvailableFilters,
  teamDonationsAvailableFilters,
  teamFundraisingAvailableFilters,
  campaignSalesAvailableFilters,
  campaignTicketsAvailableFilters,
  campaignMerchandiseAvailableFilters,
  fundraiserDonationsAvailableFilters
} from '../constants';
import { listFilterOperator } from '../../../constants/aggregate';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { instalmentFormatter } from '../../ticketing/ticketingContracts';

export const getCampaignsGrid = (translationKey, extraColumns, pageSize) => {
  const gridOptions = new GridOptions()
    .setPageSize(pageSize)
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('name', `${translationKey}.name`)
        .visibleByDefault()
        .makeClickable(getCampaignClickableColumnUrl, true, false),
      new ColumnModel('type', `${translationKey}.campaign-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('targetAmount', `${translationKey}.goalAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.donations-required`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'pagesCount',
        `${translationKey}.fundraising-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'activePagesCount',
        `${translationKey}.active-fundraising-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'fundraisersCount',
        `${translationKey}.individual-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'fundraisersActiveCount',
        `${translationKey}.active-individual-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel('teamsCount', `${translationKey}.team-pages`).setType(
        columnTypes.INT32
      ),
      new ColumnModel(
        'teamsActiveCount',
        `${translationKey}.active-team-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'collectionsCount',
        `${translationKey}.collection-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'collectionsActiveCount',
        `${translationKey}.active-collection-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel('organization.currency', `${translationKey}.currency`),
      new ColumnModel(
        'fitness.distance.alternatives.0.value',
        `${translationKey}.fitness.distance`
      ).setType(columnTypes.DISTANCE),
      new ColumnModel(
        'fitness.time.alternatives.0.value',
        `${translationKey}.fitness.time`
      ).setType(columnTypes.TIME),
      new ColumnModel(
        'fitness.optional.0.value.rawValue',
        `${translationKey}.fitness.steps`
      ).setType(columnTypes.INT32)
    ])
    .addContextMenuItem(
      'viewCampaign',
      'campaign.list.context.view-campaign',
      '/campaigns/view/${this.id}'
    )
    .addContextMenuItem(
      'editCampaign',
      'campaign.list.context.edit-campaign',
      '/campaigns/edit/${this.id}'
    )
    .addNoFilterButton('all', 'campaign.button-group.all')
    .addFilterButton('live', 'campaign.button-group.live', {
      key: 'status',
      operator: 'EqualTo',
      value: 1
    })
    .addFilterButton('complete', 'campaign.button-group.complete', {
      key: 'status',
      operator: 'EqualTo',
      value: 2
    })
    .addFilterButton('archived', 'campaign.button-group.archived', {
      key: 'status',
      operator: 'EqualTo',
      value: 3
    })
    .orderBy('raisedAmount', 'desc')
    .addAvailableFiltersKeys(campaignAvailableFiltersKeys);

  if (extraColumns && extraColumns.length > 0) {
    extraColumns.forEach((x) => {
      gridOptions.addColumn(
        new ColumnModel(
          x.path,
          `${translationKey}.${x.path || x.headerKey}`
        ).visibleByDefault()
      );
    });
  }

  return gridOptions.getState();
};

export const getCampaignSalesGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel(
        'campaign.name',
        `${translationKey}.campaign-name`
      ).visibleByDefault(),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAt',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phone-number`
      ),
      new ColumnModel(
        'financials.installments',
        `${translationKey}.instalments`,
        instalmentFormatter
      ).setVisible(false)
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(campaignSalesAvailableFilters)
    .getState();
};

export const getCampaignTicketsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization.name`
      ).visibleByDefault(),
      new ColumnModel(
        'sale.campaign.name',
        `${translationKey}.sale.campaign.name`
      ).visibleByDefault(),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'ticket.name',
        `${translationKey}.ticket.name`
      ).visibleByDefault(),
      new ColumnModel('price', `${translationKey}.price`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'sale.receiptNumber',
        `${translationKey}.sale.receiptNumber`
      ),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel('uniqueNumber', `${translationKey}.uniqueNumber`),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAtLocal',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('sale.type', `${translationKey}.sale.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phoneNumber`
      )
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(campaignTicketsAvailableFilters)
    .getState();
};

export const getCampaignMerchandiseGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization.name`
      ).visibleByDefault(),
      new ColumnModel(
        'sale.campaign.name',
        `${translationKey}.sale.campaign.name`
      ).visibleByDefault(),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'merchandiseSku.name',
        `${translationKey}.name`
      ).visibleByDefault(),
      new ColumnModel('price', `${translationKey}.price`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'sale.receiptNumber',
        `${translationKey}.sale.receiptNumber`
      ),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel('uniqueNumber', `${translationKey}.uniqueNumber`),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAtLocal',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('sale.type', `${translationKey}.sale.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phoneNumber`
      )
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(campaignMerchandiseAvailableFilters)
    .getState();
};

export const getCampaignDonationsGrid = (
  translationKey,
  clickableColumnsInfo
) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('pageType', `${translationKey}.page-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials-refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.phoneNumber',
        `${translationKey}.donor-phone-number`
      ),
      new ColumnModel('financials.currency', `${translationKey}.currency`)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .addAvailableFiltersKeys(campaignDonationsAvailableFilters)
    .getState();
};

export const getCampaignFundraisingGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel(
        'createdAtLocal',
        `${translationKey}.createdAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('phoneNumber', `${translationKey}.phoneNumber`),
      new ColumnModel('status', `${translationKey}.status`).visibleByDefault(),
      new ColumnModel(PRIVACY_COLUMN, `${translationKey}.${PRIVACY_COLUMN}`),
      new ColumnModel(
        'newsletterOptIn',
        `${translationKey}.newsletterOptIn`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.neededAverageCount`
      ).setType(columnTypes.INT32)
    ])
    .orderBy('name', 'asc')
    .addFilter('type', listFilterOperator.IN_LIST, [
      pageTypesStrings.FUNDRAISER,
      pageTypesStrings.TEAM,
      pageTypesStrings.COLLECTION
    ])
    .addAvailableFiltersKeys(campaignFundraisingAvailableFilters)
    .getState();
};

export const getTeamDonationsGrid = (translationKey, clickableColumnsInfo) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('pageType', `${translationKey}.page-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials-refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.phoneNumber',
        `${translationKey}.donor-phone-number`
      ),
      new ColumnModel('financials.currency', `${translationKey}.currency`)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .addAvailableFiltersKeys(teamDonationsAvailableFilters)
    .getState();
};

export const getTeamFundraisingGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'createdAtLocal',
        `${translationKey}.createdAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('phoneNumber', `${translationKey}.phoneNumber`),
      new ColumnModel('status', `${translationKey}.status`).visibleByDefault(),
      new ColumnModel(PRIVACY_COLUMN, `${translationKey}.${PRIVACY_COLUMN}`),
      new ColumnModel(
        'newsletterOptIn',
        `${translationKey}.newsletterOptIn`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.neededAverageCount`
      ).setType(columnTypes.INT32)
    ])
    .orderBy('name', 'asc')
    .addFilter('isFundraiserOrTeam', listFilterOperator.EQUAL_TO, 'true')
    .addAvailableFiltersKeys(teamFundraisingAvailableFilters)
    .getState();
};

export const getFundraiserDonationsGrid = (
  translationKey,
  clickableColumnsInfo
) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('pageType', `${translationKey}.page-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials-refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.phoneNumber',
        `${translationKey}.donor-phone-number`
      ),
      new ColumnModel('financials.currency', `${translationKey}.currency`)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .addAvailableFiltersKeys(fundraiserDonationsAvailableFilters)
    .getState();
};
