// React / Redux / Related
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

// Actions
import * as campaignActions from '../store/campaignActions';
import * as generalActions from '../../../modules/general';
import * as donationActions from '../../donation/store/donationActions';
import { pageActions } from '../page/reducer';
import * as ticketingActions from '../../ticketing/store/ticketingActions';
import { setRedirectUrl } from '../../../modules/session';
import CapitalizeText from '../../../components/common/capitalizeText';

// Components
import { Common } from '../../../components';
import RefundWindowContainer from '../../donation/refund-window';
import RefundSaleWindowContainer from '../../ticketing/modals/refundSaleWindow/RefundSaleWindow';
import RefundTicketWindowContainer from '../../ticketing/modals/refundTicketWindow';
import ReceiptWindowContainer from '../../donation/receipt-window';
import ReceiptSaleWindowContainer from '../../ticketing/modals/receiptSaleWindow';
import ReceiptTicketWindowContainer from '../../ticketing/modals/receiptTicketWindow';
import { ExportDataButton } from '../../../components/template/templateExportButton';
import { ExportDownloadProvider } from '../../export-history';

import { Table, Menu } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';

// Helpers
import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import { hideCondition } from '../../../helpers/hideHelper';

// Constants
import {
  gridTabs,
  ONLINE_DONATION_TYPE,
  OFFLINE_DONATION_TYPE,
  PRIVACY_COLUMN,
  privacyStrings,
  PLEDGE_DONATION_TYPE,
  TYPE_COLUMN
} from '../constants';
import RecordResultMessage from '../../../components/common/recordResult';
import { pageTypesStrings, superteam } from '../../../constants/pageTypes';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';

/** A page to view the donations and fundraisers related to a campaign */
class ListsView extends Component {
  /**
   * Changes the grid page between donations and fundraisers
   * @param {int} idx - the tab number to be shown
   */
  onFilterButtonClick(idx) {
    const paramId = get(this.props, 'match.params.id');
    this.props.generalActions.clearListsReadyState();
    this.props.history.push(`${this.props.filterPath}/${idx}/${paramId}`);
  }

  columnRender(column, value) {
    if (column.id === PRIVACY_COLUMN) {
      const text =
        value === true ? privacyStrings.PUBLIC : privacyStrings.PRIVATE;
      return <Table.Cell>{text}</Table.Cell>;
    } else if (column.id === TYPE_COLUMN) {
      return (
        <Table.Cell>
          <CapitalizeText>
            {value === pageTypesStrings.COLLECTION ? superteam : value}
          </CapitalizeText>
        </Table.Cell>
      );
    }
  }

  isFundraisersContextMenuItemHidden = (item) => {
    return item.type === pageTypesStrings.FUNDRAISER;
  };

  isDonationsContextMenuItemHidden = () => {
    return this.props.isEventAdmin;
  };

  onTransferDonationContextClick = (id, item) => {
    this.props.history.push(`/donation/${id}/transfer/setup`);
  };

  hideTransferDonationButton = (item) => {
    if (this.props.isSystemAdmin) return false;
    return item.financials.refunded || item.eventId !== null;
  };

  onViewContextClick = (id, item) => {
    this.props.history.push(`/donations/view/${id}`);
  };

  onRefundContextClick = (id, item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  onRefundSaleContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'sale');
  };

  onRefundTicketContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'ticket');
  };
  onRefundMerchandiseContextClick = (id, item) => {
    this.props.ticketingActions.showRefundConfirmation(item, 'merchandise');
  };

  onReissueContextClick = (id, item) => {
    if (get(item, 'donor.email')) {
      this.props.donationActions.showReceiptConfirmation(item);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  onReissueSaleContextClick = (id, item) => {
    if (get(item, 'customer.email')) {
      this.props.ticketingActions.showReceiptConfirmation(item, 'sale');
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  onReissueTicketContextClick = (id, item) => {
    if (get(item, 'customer.email')) {
      this.props.ticketingActions.showReceiptConfirmation(item, 'ticket');
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  isReissueHidden = (item) => {
    return get(item, 'financials.refunded');
  };

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
    this.props.donationActions.clearRecordMessages('saveRecord');
    this.props.ticketingActions.clearRecordMessages('saveRecord');
  };

  onAddOfflineDonation = () => {
    const id = get(this.props, 'match.params.id');
    const campaignId = get(this.props, 'match.params.campaignId');
    const viewType = this.props.viewType;
    if (!viewType) {
      this.props.history.push(`/donations/create/${id}/donation-detail`);
      return;
    }

    if (campaignId) {
      this.props.history.push(
        `/donations/create/${campaignId}/${viewType}/${id}/donation-detail`
      );
      return;
    }

    this.props.history.push('/donations/create/donation-detail');
  };

  onAddOfflineSale = () => {
    const id = get(this.props, 'match.params.id');
    this.props.history.push(`/sales/create/${id}/invoice-information`);
  };

  hideRefundButton = (item) => {
    return item.type !== ONLINE_DONATION_TYPE;
  };

  hideDeleteButton = (item) => {
    return (
      item.type !== OFFLINE_DONATION_TYPE && item.type !== PLEDGE_DONATION_TYPE
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  hideViewButton = (item) => {
    return !this.props.isSystemAdmin;
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.donationList) ||
      Record.isRecordLoading(this.props.fundraiserList)
    );
  };

  render() {
    if (Record.isRecordLoading(this.props.exportFileRecord)) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;
    const gridTab = get(this.props, 'match.params.view');
    const ticketsBaseFilter = [
      {
        key: 'sale.campaignId',
        operator: 'EqualTo',
        value: get(this.props, 'match.params.id')
      },
      {
        key: 'type',
        operator: 'EqualTo',
        value: 'Ticket'
      }
    ];
    const additionalTicketFilters = [
      {
        key: 'campaignId',
        operator: 'EqualTo',
        value: get(this.props, 'match.params.id')
      },
      { key: 'enabled', operator: 'EqualTo', value: true }
    ];
    const merchandiseBaseFilter = [
      {
        key: 'sale.campaignId',
        operator: 'EqualTo',
        value: get(this.props, 'match.params.id')
      },
      {
        key: 'type',
        operator: 'EqualTo',
        value: 'merchandise'
      }
    ];
    const additionalMerchandiseFilters = [
      {
        key: 'campaignId',
        operator: 'EqualTo',
        value: get(this.props, 'match.params.id')
      },
      { key: 'enabled', operator: 'EqualTo', value: true }
    ];

    const tabs = (
      <Menu
        className="accounts-tab-buttons-group"
        style={{ margin: '0px', borderRight: '0px' }}
      >
        {!this.props.isEventAdmin && this.props.isTicketingAvailable && (
          <Menu.Item
            id="0"
            active={gridTab === gridTabs.SALES}
            onClick={this.onFilterButtonClick.bind(this, gridTabs.SALES)}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value="campaign.list.view.sales" />
          </Menu.Item>
        )}
        {!this.props.isEventAdmin && this.props.isTicketingAvailable && (
          <Menu.Item
            id="1"
            active={gridTab === gridTabs.TICKETS}
            onClick={this.onFilterButtonClick.bind(this, gridTabs.TICKETS)}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value="campaign.list.view.tickets" />
          </Menu.Item>
        )}
        {!this.props.isEventAdmin && this.props.isMerchandisingAvailable && (
          <Menu.Item
            id="2"
            active={gridTab === gridTabs.MERCHANDISE}
            onClick={this.onFilterButtonClick.bind(this, gridTabs.MERCHANDISE)}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value="campaign.list.view.merchandise" />
          </Menu.Item>
        )}
        {!this.props.isEventAdmin && (
          <Menu.Item
            id="3"
            active={gridTab === gridTabs.DONATIONS}
            onClick={this.onFilterButtonClick.bind(this, gridTabs.DONATIONS)}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value="campaign.list.view.donations" />
          </Menu.Item>
        )}
        {this.props.showFundraisers && (
          <Menu.Item
            id="4"
            active={gridTab === gridTabs.FUNDRAISERS}
            onClick={this.onFilterButtonClick.bind(this, gridTabs.FUNDRAISERS)}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value="campaign.list.view.fundraisers" />
          </Menu.Item>
        )}
      </Menu>
    );

    const commonGridProps = {
      shortDescription: this.props.shortDescription,
      toggleColumns: true,
      filtersButton: true,
      search: true,
      hideColumn: this.hideColumnCondition
    };

    return (
      <div>
        <RecordResultMessage
          record={this.props.donationSaveRecord}
          onDismiss={this.clearMessages}
        />
        <RefundWindowContainer />
        <RefundSaleWindowContainer />
        <RefundTicketWindowContainer />
        <RefundTicketWindowContainer path="merchandise" />
        <ReceiptWindowContainer />
        <ReceiptSaleWindowContainer />
        <ReceiptTicketWindowContainer />
        {tabs}
        {gridTab === gridTabs.SALES && (
          <Grid.Managed
            id="salesList"
            listKey={this.props.salesListKey}
            list={this.props.salesList}
            actions={this.props.saleListActions}
            filter={this.props.baseFilter}
            {...commonGridProps}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="sale" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    versions={['v3']}
                    onExportClick={(_) => open(this.props.salesList.request)}
                  />
                )}
              </ExportDownloadProvider>
              <Common.Button
                id="add-offline-sale"
                onClick={this.onAddOfflineSale}
              >
                <Translate value="campaign.list.view.add-offline-sale" />
              </Common.Button>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE.key}
                label={I18n.t('sale.list.context.view-sale')}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_SALE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE.key}
                label={I18n.t('sale.list.context.edit-sale')}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_SALE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              {/* <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_SALE.key}
                label={I18n.t('sale.list.context.refund-sale')}
                onClick={this.onRefundSaleContextClick}
              /> */}
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REISSUE_INVOICE.key}
                label={I18n.t('sale.list.context.reissue-invoice')}
                onClick={this.onReissueSaleContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.TICKETS && (
          <Grid.Managed
            id="ticketsList"
            listKey={this.props.ticketsListKey}
            list={this.props.ticketsList}
            actions={this.props.tikcetListActions}
            filter={ticketsBaseFilter}
            additionalFilters={additionalTicketFilters}
            {...commonGridProps}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="saleDetail" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    versions={['v2', 'v3']}
                    onExportClick={(version) => {
                      let additionalData = {};
                      if (version === 'v3') {
                        additionalData.layout = 'Extended';
                      }

                      open({
                        ...additionalData,
                        ...(this.props.ticketsList.request || {})
                      });
                    }}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET.key}
                label={I18n.t('ticket.list.context.edit-ticket')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_TICKET
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_TICKET.key}
                label={I18n.t('ticket.list.context.refund-ticket')}
                onClick={this.onRefundTicketContextClick}
              />
              <ContextMenu.Item
                isHiden={this.isReissueHidden}
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REISSUE_TICKET.key}
                label={I18n.t('ticket.list.context.reissue-ticket')}
                onClick={this.onReissueTicketContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.MERCHANDISE && (
          <Grid.Managed
            shortDescription={this.props.shortDescription}
            id="merchandiseList"
            listKey={this.props.merchandiseListKey}
            list={this.props.merchandiseList}
            actions={this.props.merchandiseListActions}
            filter={merchandiseBaseFilter}
            additionalFilters={additionalMerchandiseFilters}
            {...commonGridProps}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="saleDetail" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    versions={['v3']}
                    onExportClick={(_) =>
                      open(this.props.merchandiseList.request)
                    }
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_MERCHANDISE.key}
                label={I18n.t('ticket.list.context.edit-ticket')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_MERCHANDISE
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_TICKET.key}
                label={I18n.t('ticket.list.context.refund-ticket')}
                onClick={this.onRefundMerchandiseContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === gridTabs.DONATIONS && (
          <Grid.Managed
            shortDescription={this.props.shortDescription}
            id="donationList"
            listKey={this.props.donationListKey}
            list={this.props.donationList}
            actions={this.props.donationListActions}
            filter={this.props.baseFilter}
            url={this.props.campaignUrl}
            {...commonGridProps}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    onExportClick={(version) => {
                      let additionalData = {};
                      if (version === 'v3') {
                        additionalData.layout = 'Extended';
                      } else if (version === 'v4') {
                        additionalData.layout = 'FundraiserData';
                      }

                      open({
                        ...additionalData,
                        ...(this.props.donationList.request || {})
                      });
                    }}
                  />
                )}
              </ExportDownloadProvider>
              {this.props.viewType !== pageTypesStrings.COLLECTION && (
                <Common.Button
                  id="add-offline-donation"
                  onClick={this.onAddOfflineDonation}
                >
                  <Translate value="campaign.list.view.add-offline-donation" />
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_DONATION
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_DONATION
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.hideRefundButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.hideDeleteButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueContextClick}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.TRANSFER_DONATION.key}
                label={I18n.t('donation.list.context.transfer-donation')}
                onClick={this.onTransferDonationContextClick}
                isHiden={this.hideTransferDonationButton}
              />
            </ContextMenu>
          </Grid.Managed>
        )}

        {gridTab === gridTabs.FUNDRAISERS && (
          <Grid.Managed
            shortDescription={this.props.shortDescription}
            id="fundraisersList"
            listKey={this.props.fundraiserListKey}
            list={this.props.fundraiserList}
            columnRender={this.columnRender}
            actions={this.props.fundraiserListActions}
            filter={this.props.baseFilter}
            searchVersion="v3"
            url={this.props.campaignUrl}
            {...commonGridProps}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="page" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    versions={['v2', 'v3']}
                    onExportClick={(version) => {
                      let additionalData = {};
                      if (version === 'v3') {
                        additionalData.layout = 'Extended';
                      }

                      open({
                        ...additionalData,
                        ...(this.props.fundraiserList.request || {})
                      });
                    }}
                  />
                )}
              </ExportDownloadProvider>
              {this.props.viewType !== pageTypesStrings.COLLECTION && (
                <Common.Button
                  id="add-offline-donation"
                  onClick={this.onAddOfflineDonation}
                >
                  <Translate value="campaign.list.view.add-offline-donation" />
                </Common.Button>
              )}
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_PAGE.key}
                label={I18n.t('campaign.list.view.context.edit-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.EDIT_PAGE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_PUBLIC.key}
                label={I18n.t('campaign.list.view.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_PUBLIC
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_DONATIONS.key}
                label={I18n.t('campaign.list.view.context.view-page-donations')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_DONATIONS
                }
                isHiden={this.isDonationsContextMenuItemHidden}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_FUNDRAISERS.key}
                label={I18n.t(
                  'campaign.list.view.context.view-page-fundraisers'
                )}
                isHiden={this.isFundraisersContextMenuItemHidden}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.CAMPAIGN_DETAIL.VIEW_FUNDRAISERS
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
      </div>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, donation, session }, ownProps) => {
  const gridTab = campaign.gridTab;
  const exportFileRecord = campaign.exportFileRecord;

  const salesGrid = Common.Grid.Managed.mapGridState(
    campaign,
    ownProps.salesGridKey
  );
  const ticketsGrid = Common.Grid.Managed.mapGridState(
    campaign,
    ownProps.ticketsGridKey
  );
  const merchandiseGrid = Common.Grid.Managed.mapGridState(
    campaign,
    ownProps.merchandiseGridKey
  );
  const donationGrid = Common.Grid.Managed.mapGridState(
    campaign,
    ownProps.donationsGridKey
  );
  const fundraiserGrid = Common.Grid.Managed.mapGridState(
    campaign,
    ownProps.fundraisersGridKey
  );

  const isSystemAdmin = get(session, 'isSystemAdmin');
  const isOrganizationAdmin = get(session, 'isOrganizationAdmin');
  const isCampaignAdmin = get(session, 'isCampaignAdmin');
  const isEventAdmin = get(session, 'isEventAdmin');

  return {
    // The campaign record
    gridTab,
    exportFileRecord,
    donationSaveRecord: donation.saveRecord,
    campaignRecord: campaign.record,

    // The list data for campaign sales
    salesListKey: salesGrid.key,
    salesTimestamp: salesGrid.timestamp,
    salesList: salesGrid.list,

    // The list data for campaign sales
    ticketsListKey: ticketsGrid.key,
    ticketsTimestamp: ticketsGrid.timestamp,
    ticketsList: ticketsGrid.list,

    // The list data for campaign merchandise sales
    merchandiseListKey: merchandiseGrid.key,
    merchandiseTimestamp: merchandiseGrid.timestamp,
    merchandiseList: merchandiseGrid.list,

    // The list data for campaign donations
    donationListKey: donationGrid.key,
    donationTimestamp: donationGrid.timestamp,
    donationList: donationGrid.list,

    // The list data for campaign fundraisers
    fundraiserListKey: fundraiserGrid.key,
    fundraiserTimestamp: fundraiserGrid.timestamp,
    fundraiserList: fundraiserGrid.list,

    isSystemAdmin,
    isOrganizationAdmin,
    isCampaignAdmin,
    isEventAdmin,
    campaignUrl: get(campaign, 'record.data.data.url')
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  // Maps the actions to the properties.
  const dispatchers = bindActionCreators(campaignActions, dispatch);

  // Maps the required functions for the managed list to a prop.
  dispatchers.saleListActions = Common.Grid.Managed.bindGridActions(dispatch, {
    getMetadata: campaignActions.getSaleMetadata,
    getListData: campaignActions.getSaleListData,
    getListDataBySearchTerm: campaignActions.getSaleListDataBySearchTerm,
    toggleColumnsChange: campaignActions.toggleColumnsChange
  });

  // Maps the required functions for the managed list to a prop.
  dispatchers.tikcetListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: campaignActions.getTicketMetadata,
      getListData: campaignActions.getTicketListData,
      getListDataBySearchTerm: campaignActions.getTicketListDataBySearchTerm,
      toggleColumnsChange: campaignActions.toggleColumnsChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.merchandiseListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: campaignActions.getTicketMetadata,
      getListData: campaignActions.getTicketListData,
      getListDataBySearchTerm: campaignActions.getTicketListDataBySearchTerm,
      toggleColumnsChange: campaignActions.toggleColumnsChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.donationListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: campaignActions.getDonationMetadata,
      getListData: campaignActions.getDonationListData,
      getListDataBySearchTerm: campaignActions.getDonationListDataBySearchTerm,
      toggleColumnsChange: campaignActions.toggleColumnsChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.fundraiserListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: campaignActions.getFundraiserMetadata,
      getListData: campaignActions.getFundraiserListData,
      getListDataBySearchTerm:
        campaignActions.getFundraiserListDataBySearchTerm,
      toggleColumnsChange: campaignActions.toggleColumnsChange
    }
  );

  dispatchers.generalActions = bindActionCreators(generalActions, dispatch);
  dispatchers.pageActions = bindActionCreators(pageActions, dispatch);
  dispatchers.donationActions = bindActionCreators(donationActions, dispatch);
  dispatchers.ticketingActions = bindActionCreators(ticketingActions, dispatch);
  dispatchers.setRedirectUrl = bindActionCreators(setRedirectUrl, dispatch);
  return dispatchers;
};

const ListsViewContainer = withRouter(
  connect(mapState, mapDispatch)(ListsView)
);
export default ListsViewContainer;
