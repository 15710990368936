import React, { Component } from 'react';
import get from 'lodash.get';
import { cloneDeep } from 'lodash';
import { Form } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import update from '../../../../helpers/update';
import { TARGET_VALIDATION } from '../../../../constants/validationModels';
import { fitnessMeasure, fitnessMeasureUnit } from '../../constants';
import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import * as templateHelper from '../../../../components/template/templateHelper';
import { TemplateCurrency } from '../../../../components/template/templateCurrency';
import { TemplateCheckbox } from '../../../../components/template/templateCheckbox';
import { TemplateHorizontalRule } from '../../../../components/template/templateHorizontalRule';
import { TemplateDropdown } from '../../../../components/template/templateDropdown';
import { TemplateDatePicker } from '../../../../components/template/templateDatePicker';
import FitnessActivities from '../../../../components/common/fitnessElements/fitnessActivities';
import FitnessTrackedStatistics from '../../../../components/common/fitnessElements/fitnessTrackedStatistics';
import FitnessThermometersToShow from '../../../../components/common/fitnessElements/fitnessThermometersToShow';
import FitnessLeaderboardsToShow from '../../../../components/common/fitnessElements/fitnessLeaderboardsToShow';
import FitnessPlatforms from '../../../../components/common/fitnessElements/fitnessPlatforms';
import { isArrayWithItems } from '../../../../helpers/arrayHelper';
import { fitnessDateRuleOptions } from '../../../../constants/metadataConstants';
import { FitnessContract } from './constants';
import { TemplateToogle } from '../../../../components/template/templateToggle';

interface FitnessTemplateViewProps {
  fitnessTargets: any;
  isSystemAdmin: any;
  changeItemVisibility: (
    contractPath: string,
    arg1: boolean,
    arg2: boolean
  ) => void;
  setResultValue: (oldV?: any, nValue?: any) => void;
}

class FitnessTemplateView extends Component<FitnessTemplateViewProps> {
  target: { amount: boolean; maxValue: number; required: boolean };
  fitnessActivitiesValidation: { fitnessActivities: boolean };
  fitnessTrackedStatisticsValidation: { fitnessTrackedStatistics: boolean };
  fitnessTargetsValidation: {
    fitnessTargets: boolean;
    optionalParamPath: string;
  };

  constructor(props: FitnessTemplateViewProps) {
    super(props);

    this.target = {
      required: false,
      ...TARGET_VALIDATION
    };
    this.fitnessActivitiesValidation = {
      fitnessActivities: true
    };
    this.fitnessTrackedStatisticsValidation = {
      fitnessTrackedStatistics: true
    };
    this.fitnessTargetsValidation = {
      fitnessTargets: true,
      optionalParamPath: FitnessContract.fitnessThermometersToShow
    };
  }

  onChange = (
    path: string,
    value: string | number | boolean | any[] | null
  ) => {
    this.props.setResultValue(path, value);
  };

  onFundraiserFitnessTargetAmountChange = (key: string, value: any) => {
    const targetIndex = isArrayWithItems(this.props.fitnessTargets)
      ? this.props.fitnessTargets.findIndex((item: any) => item.key === key)
      : -1;

    if (targetIndex !== -1) {
      this.onChange(
        'fitnessSetup.targets',
        update(this.props.fitnessTargets, {
          [targetIndex]: {
            fundraiserDefaultTarget: {
              $set: value
            }
          }
        })
      );
    } else {
      const newFitnessTargets = [
        ...this.props.fitnessTargets,
        {
          key,
          fundraiserDefaultTarget: value,
          teamDefaultTarget: null,
          target: null
        }
      ];
      this.onChange(FitnessContract.fitnessTargets, newFitnessTargets);
    }
  };

  onTeamFitnessTargetAmountChange = (key: string, value: any) => {
    const targetIndex = isArrayWithItems(this.props.fitnessTargets)
      ? this.props.fitnessTargets.findIndex((item: any) => item.key === key)
      : -1;

    if (targetIndex !== -1) {
      this.onChange(
        FitnessContract.fitnessTargets,
        update(this.props.fitnessTargets, {
          [targetIndex]: {
            teamDefaultTarget: {
              $set: value
            }
          }
        })
      );
    } else {
      const newFitnessTargets = [
        ...this.props.fitnessTargets,
        {
          key,
          fundraiserDefaultTarget: null,
          teamDefaultTarget: value,
          target: null
        }
      ];
      this.onChange(FitnessContract.fitnessTargets, newFitnessTargets);
    }
  };

  onAllowFundraiserAndTeamFitnessTargetsChange = (path: any, value: any) => {
    this.props.changeItemVisibility(FitnessContract.fundraiserDates, value, true);
    
    const isTargetsAvailable = isArrayWithItems(this.props.fitnessTargets);
    const fitnessDistanceTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.distance
      );
    const fitnessTimeTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.time
      );
    const fitnessNumberOfStepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.numberOfSteps
      );
    const fitnessRepetitionsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.reps
      );

    if (isTargetsAvailable && !value) {
      let fitnessTargets = cloneDeep(this.props.fitnessTargets);

      const fitnessDistanceTargetIndex = this.props.fitnessTargets.indexOf(
        fitnessDistanceTarget
      );
      const fitnessTimeTargetIndex =
        this.props.fitnessTargets.indexOf(fitnessTimeTarget);
      const fitnessNumberOfStepsTargetIndex = this.props.fitnessTargets.indexOf(
        fitnessNumberOfStepsTarget
      );
      const fitnessRepetitionsTargetIndex = this.props.fitnessTargets.indexOf(
        fitnessRepetitionsTarget
      );

      if (fitnessDistanceTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessDistanceTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      if (fitnessTimeTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessTimeTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      if (fitnessNumberOfStepsTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessNumberOfStepsTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      if (fitnessRepetitionsTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessRepetitionsTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      this.onChange(FitnessContract.fitnessTargets, fitnessTargets);
    }

    this.onChange(path, value);
  };

  render() {
    const isTargetsAvailable = isArrayWithItems(this.props.fitnessTargets);
    const fitnessDistanceTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.distance
      );
    const fitnessTimeTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.time
      );
    const fitnessNumberOfStepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.numberOfSteps
      );
    const fitnessRepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target: { key: string }) => target.key === fitnessMeasure.reps
      );

    return (
      <>
        <TemplateHorizontalRule path={FitnessContract.fitnessAllowedTypes} />
        <FitnessActivities
          path={FitnessContract.fitnessAllowedTypes}
          onChange={this.onChange}
          validation={this.fitnessActivitiesValidation}
        />
        <TemplateHorizontalRule
          path={FitnessContract.fitnessAllowedTrackedStatistics}
        />
        <FitnessTrackedStatistics
          path={FitnessContract.fitnessSetup}
          onChange={this.onChange}
          validation={this.fitnessTrackedStatisticsValidation}
        />
        {/* <FitnessStatisticsToShow
						path={campaignContract.fitnessTargets}
						onChange={this.onChange}
						validation={this.fitnessTargetsValidation}
					/> */}
        <TemplateHorizontalRule
          path={FitnessContract.fitnessThermometersToShow}
        />
        <FitnessThermometersToShow
          path={FitnessContract.fitnessTargets}
          onChange={this.onChange}
          onChangeItemVisibility={this.props.changeItemVisibility}
          validation={this.fitnessTargetsValidation}
        />
        <TemplateHorizontalRule
          path={FitnessContract.fitnessLeaderboardsToShow}
        />
        <FitnessLeaderboardsToShow
          path={FitnessContract.fitnessLeaderboardsToShow}
          onChange={this.onChange}
        />
        <TemplateHorizontalRule
          path={FitnessContract.fitnessAllowedPlatforms}
        />
        <FitnessPlatforms
          path={FitnessContract.fitnessAllowedPlatforms}
          onChange={this.onChange}
        />
        <TemplateHorizontalRule path={FitnessContract.allowPageTracking} />
        <TemplateCheckbox
          id={FitnessContract.allowPageTracking}
          path={FitnessContract.allowPageTracking}
          inputLabel="template.page.content.fitness.allowPageTracking.checkbox"
          onChange={this.onAllowFundraiserAndTeamFitnessTargetsChange}
        />

        <TemplateToogle path={FitnessContract.allowPageTracking}>
          <>
            {this.props.isSystemAdmin && (
              <Form.Group widths="equal" path={FitnessContract.fundraiserDates}>
                <TemplateDatePicker
                  path={FitnessContract.fitnessValidFrom}
                  label="template.page.content.fitness.fitnessThermometersToShow.targets.validFrom"
                  onChange={this.onChange}
                  onClear={() =>
                    this.onChange(FitnessContract.fitnessValidFrom, null)
                  }
                  validation={{
                    startDateRange: true,
                    optionalParamPath: FitnessContract.fitnessValidTo
                  }}
                />
                <TemplateDatePicker
                  path={FitnessContract.fitnessValidTo}
                  label="template.page.content.fitness.fitnessThermometersToShow.targets.validTo"
                  onChange={this.onChange}
                  onClear={() =>
                    this.onChange(FitnessContract.fitnessValidTo, null)
                  }
                />
                <TemplateDropdown
                  className="valid-date-rule-dropdown"
                  path={FitnessContract.fitnessValidDateRule}
                  label="template.page.content.fitness.fitnessThermometersToShow.targets.validDateRule"
                  onChange={this.onChange}
                  options={fitnessDateRuleOptions}
                  hasEmptyOption
                />
              </Form.Group>
            )}
            <TemplateCurrency
              path={FitnessContract.fundraiserDistanceTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.distance}
              value={
                fitnessDistanceTarget
                  ? fitnessDistanceTarget.fundraiserDefaultTarget
                  : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserDistance"
              onChange={(_path: any, value: any) =>
                this.onFundraiserFitnessTargetAmountChange(
                  fitnessMeasure.distance,
                  value
                )
              }
            />
            <TemplateCurrency
              path={FitnessContract.fundraiserTimeTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.time}
              value={
                fitnessTimeTarget ? fitnessTimeTarget.fundraiserDefaultTarget : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserTime"
              onChange={(_path: any, value: any) =>
                this.onFundraiserFitnessTargetAmountChange(
                  fitnessMeasure.time,
                  value
                )
              }
            />
            <TemplateCurrency
              path={FitnessContract.fundraiserNumberOfStepsTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.numberOfSteps}
              value={
                fitnessNumberOfStepsTarget
                  ? fitnessNumberOfStepsTarget.fundraiserDefaultTarget
                  : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserNumberOfSteps"
              onChange={(_path: any, value: any) =>
                this.onFundraiserFitnessTargetAmountChange(
                  fitnessMeasure.numberOfSteps,
                  value
                )
              }
            />
            <TemplateCurrency
              path={FitnessContract.fundraiserRepsTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.repetitions}
              value={
                fitnessRepsTarget ? fitnessRepsTarget.fundraiserDefaultTarget : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.fundraiserReps"
              onChange={(_path: any, value: any) =>
                this.onFundraiserFitnessTargetAmountChange(
                  fitnessMeasure.reps,
                  value
                )
              }
            />
            <TemplateCurrency
              path={FitnessContract.teamDistanceTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.distance}
              value={
                fitnessDistanceTarget ? fitnessDistanceTarget.teamDefaultTarget : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.teamDistance"
              onChange={(_path: any, value: any) =>
                this.onTeamFitnessTargetAmountChange(fitnessMeasure.distance, value)
              }
            />
            <TemplateCurrency
              path={FitnessContract.teamTimeTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.time}
              value={fitnessTimeTarget ? fitnessTimeTarget.teamDefaultTarget : ''}
              label="template.page.content.fitness.fitnessThermometersToShow.targets.teamTime"
              onChange={(_path: any, value: any) =>
                this.onTeamFitnessTargetAmountChange(fitnessMeasure.time, value)
              }
            />
            <TemplateCurrency
              path={FitnessContract.teamNumberOfStepsTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.numberOfSteps}
              value={
                fitnessNumberOfStepsTarget
                  ? fitnessNumberOfStepsTarget.teamDefaultTarget
                  : ''
              }
              label="template.page.content.fitness.fitnessThermometersToShow.targets.teamNumberOfSteps"
              onChange={(_path: any, value: any) =>
                this.onTeamFitnessTargetAmountChange(
                  fitnessMeasure.numberOfSteps,
                  value
                )
              }
            />
            <TemplateCurrency
              path={FitnessContract.teamRepsTarget}
              validation={this.target}
              inputLabel={fitnessMeasureUnit.repetitions}
              value={fitnessRepsTarget ? fitnessRepsTarget.teamDefaultTarget : ''}
              label="template.page.content.fitness.fitnessThermometersToShow.targets.teamReps"
              onChange={(_path: any, value: any) =>
                this.onTeamFitnessTargetAmountChange(fitnessMeasure.reps, value)
              }
            />
          </>
        </TemplateToogle>
      </>
    );
  }
}

const mapState = (state: { templateBuilderEx: { data: any }, campaign: any }) => {
  return {
    fitnessEnabled: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessEnabled
    ),
    fitnessThermometersToShow: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessThermometersToShow
    ),
    fitnessTargets: templateHelper.getValue(
      state.templateBuilderEx,
      FitnessContract.fitnessTargets
    ),
    isSystemAdmin: get(state, 'session.isSystemAdmin')
  };
};

const mapDispatch = (dispatch: any) => {
  // @ts-ignore
  return bindActionCreators(templateBuilderActions, dispatch);
};

export const FitnessTemplate = withRouter(
  // @ts-ignore
  connect(mapState, mapDispatch)(FitnessTemplateView)
);
