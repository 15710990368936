import React, {
  FunctionComponent,
  useState,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";

import { cardActions } from "../../modules/card";
import * as templateHelper from "./templateHelper";
import config from "../../constants";
import MakePaymentForm from "../common/donateElements/checkoutForm";

interface TemplateCardProps {
  accountKey: string;
  label: string;
  path: string;
  donor: any;
  validation?: any;
  onChange: (path: string, value: any) => void;
  makePayment: (data: any) => void;
}

export const TemplateCard: FunctionComponent<TemplateCardProps> = (props) => {
  const {
    accountKey,
    label,
    path,
    donor,
    validation,
    onChange: handleChange,
    makePayment,
  } = props;

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accountKey) {
      setStripePromise(
        loadStripe(
          config.STRIPE_PUBLIC_KEY as string,
          { stripeAccount: accountKey }
        )
      );
    }
  }, [accountKey]);

  useEffect(() => {
    return () => {
      handleChange(`${path}.complete`, null);
      handleChange(`${path}.error`, null);

      dispatch(cardActions.clear(path));
    };
  }, []);

  return (
    <div>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <MakePaymentForm
            required={validation && validation.required}
            label={templateHelper.getTranslation(label)}
            path={path}
            donor={donor}
            onChange={handleChange}
            makePayment={makePayment}
          />
        </Elements>
      )}
      {!stripePromise && (
        <Message negative>
          <Message.Header>
            We're sorry. Card can not be connected at the time.
          </Message.Header>
          <p className="error">
            Our team is already working on fixing the issue.
          </p>
        </Message>
      )}
    </div>
  );
};

export default TemplateCard;
