import React, { Component } from 'react';
import { Form, Button, Segment, Icon } from 'semantic-ui-react';
import ValidatedInput from '../../components/common/validatedInput';
import { roles, roleOptions } from '../../constants/roles';
import { connect } from 'react-redux';
import {
  onInviteAdminParamChange,
  inviteAdmin,
  getOrgOptions,
  onInvitePanelValidationChange,
  getCampaignOptions,
  getEventOptions,
  getEventOrganizerOptions
} from './store/accountActions';
import { Form as CommonForm } from '../../components';
import { paths, getInviteAdminValidation } from './constants';
import './index.css';
import { I18n } from 'react-redux-i18n';
import { Record } from '../../modules/bin/utility';
import get from 'lodash.get';
import * as validationHelper from '../../helpers/validationHelper';

class InviteAdmin extends Component {
  componentDidUpdate() {
    if (
      this.props.isOrganizationAdmin &&
      !this.props.inviteAdmin.params.organizationId
    ) {
      this.onOrganizationChange(undefined, {
        value: get(this.props, 'organization.id')
      });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const validationModels = this.getValidationModels(nextProps);
    const isAllFieldsValid =
      validationHelper.isAllFieldsValid(validationModels);

    if (nextProps.isInvitePanelValid !== isAllFieldsValid) {
      this.props.onInvitePanelValidationChange(isAllFieldsValid);
    }
  };

  onInputChange = (path, value) => {
    this.props.onInviteAdminParamChange(path, value);
  };

  onInviteClick = () => {
    let { params } = this.props.inviteAdmin;

    switch (params.roles) {
      case roles.ORGANIZATION_ADMIN:
      case roles.ORGANIZATION_ADMIN_RESTRICTED: {
        const { roleDetail = {} } = params;
        this.props.onInviteAdminClick({
          asInvite: true,
          ...params,
          roles: roles.ORGANIZATION_ADMIN,
          roleDetail: { 
            ...roleDetail,
            restrictExports: params.roles === roles.ORGANIZATION_ADMIN_RESTRICTED
          }
        });
        break;
      }
      default: {
        this.props.onInviteAdminClick({
          asInvite: true,
          ...params
        });
        break;
      }
    }
  };

  onOrganizationSearch = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getOrgOptions(searchTerm.searchQuery);
    }, 500);
  };

  onCampaignSearch = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getCampaignOptions(searchTerm.searchQuery);
    }, 500);
  };

  onEventSearch = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getEventOptions(searchTerm.searchQuery);
    }, 500);
  };

  onEventOrganizerSearch = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getEventOrganizerOptions(searchTerm.searchQuery);
    }, 500);
  };

  organizationClear = () => {
    this.props.getOrgOptions('');
  };

  campaignClear = () => {
    this.props.getCampaignOptions('');
  };

  eventClear = () => {
    this.props.getEventOptions('');
  };

  eventOrganizerClear = () => {
    this.props.getEventOrganizerOptions('');
  };

  onOrganizationChange = (evt, obj) => {
    this.onInputChange(paths.INVITE_ADMIN_ORGANIZATION, obj.value);
  };

  onCampaignChange = (evt, obj) => {
    this.onInputChange(paths.INVITE_ADMIN_CAMPAIGN, [obj.value]);
  };

  onEventChange = (evt, obj) => {
    this.onInputChange(paths.INVITE_ADMIN_EVENT, [obj.value]);
  };

  onEventOrganizerChange = (evt, obj) => {
    this.onInputChange(paths.INVITE_ADMIN_EVENT_ORGANIZER, [obj.value]);
  };

  handleRoleChange = (e, { value }) => {
    if (value !== this.props.inviteAdmin.params.roles) {
      this.onInputChange(paths.INVITE_ADMIN_ROLE, value);
      this.onInputChange(paths.INVITE_ADMIN_RESTRICT_EXPORTS, value === roles.ORGANIZATION_ADMIN_RESTRICTED);
    }
  };

  getValidationModels = (props) => {
    const inviteAdminValidation = getInviteAdminValidation(
      this.props.inviteAdmin.params.roles
    );
    const validationModels = Object.keys(inviteAdminValidation).map((item) => {
      const validation = inviteAdminValidation[item];
      return {
        validationModel: validation,
        value: get(props.inviteAdmin.params, validation.path)
      };
    });

    return validationModels;
  };

  getOrgOptions = () => {
    let options = [];
    const { list } = this.props.inviteAdmin.orgOptions.data;
    if (list) {
      options = list.map((x) => this.getOrganizationItem(x));
    }

    return options;
  };

  getCampaignOptions = () => {
    let options = [];
    const { list } = this.props.inviteAdmin.campaignOptions.data;
    if (list) {
      options = list.map((x) => this.getCampaignItem(x));
    }

    return options;
  };

  getEventOptions = () => {
    let options = [];
    const { list } = this.props.inviteAdmin.eventOptions.data;
    if (list) {
      options = list.map((x) => this.getEventItem(x));
    }

    return options;
  };

  getEventOrganizerOptions = () => {
    let options = [];
    const { list } = this.props.inviteAdmin.eventOrganizerOptions.data;
    if (list) {
      options = list.map((x) => this.getEventOrganizerItem(x));
    }

    return options;
  };

  getOrganizationItem = (item) => {
    return {
      text: `${item.name} (${item.id} - ${item.url})`,
      value: item.id
    };
  };

  getCampaignItem = (item) => {
    return {
      text: `${item.name} (${item.id} - ${item.url})`,
      value: item.id
    };
  };

  getEventItem = (item) => {
    return {
      text: `${item.name} (${item.id} - ${item.url})`,
      value: item.id
    };
  };

  getEventOrganizerItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  render() {
    if (!this.props.inviteAdmin.isPanelVisible) {
      return null;
    }

    const { params } = this.props.inviteAdmin;

    const orgOptions = this.getOrgOptions();
    const campaignOptions = this.getCampaignOptions();
    const eventOptions = this.getEventOptions();
    const eventOrganizerOptions = this.getEventOrganizerOptions();
    const inviteAdminValidation = getInviteAdminValidation(params.roles);

    return (
      <Segment style={{ backgroundColor: 'rgba(216, 216, 216, 0.05)' }}>
        <Form>
          <Icon
            link
            className="right-float"
            name="close"
            onClick={this.props.onDismissClick}
          />
          <Form.Group>
            <Form.Field width={4}>
              <ValidatedInput
                id="invitePanelFirstName"
                label={I18n.t('account.invite-admin.first-name')}
                value={params.firstName}
                onChange={(e, { value }) => {
                  this.onInputChange(paths.INVITE_ADMIN_FIRST_NAME, value);
                }}
                validation={inviteAdminValidation.FIRST_NAME}
              />
            </Form.Field>
            <Form.Field width={4}>
              <ValidatedInput
                id="invitePanelLastName"
                label={I18n.t('account.invite-admin.last-name')}
                value={params.lastName}
                onChange={(e, { value }) => {
                  this.onInputChange(paths.INVITE_ADMIN_LAST_NAME, value);
                }}
                validation={inviteAdminValidation.LAST_NAME}
              />
            </Form.Field>
            <Form.Field width={4}>
              <ValidatedInput
                id="invitePanelEmail"
                label={I18n.t('account.invite-admin.email')}
                value={params.email}
                validation={inviteAdminValidation.EMAIL}
                onChange={(e, { value }) => {
                  this.onInputChange(paths.INVITE_ADMIN_EMAIL, value);
                }}
              />
            </Form.Field>

          </Form.Group>
          <Form.Group className='invite-panel-fields'>
            <Form.Select
              id="invitePanelRole"
              fluid
              label={I18n.t('account.invite-admin.role')}
              options={roleOptions.filter((options) =>
                options.isVisible(this.props.isSystemAdmin)
              )}
              value={params.roles}
              width={4}
              required={inviteAdminValidation.ROLE.required}
              onChange={this.handleRoleChange}
            />

            {!this.props.isOrganizationAdmin &&
              (params.roles === roles.ORGANIZATION_ADMIN || params.roles === roles.ORGANIZATION_ADMIN_RESTRICTED) && (
                <CommonForm.Autocomplete
                  id="invitePanelOrganization"
                  label="Organization"
                  value={params.organizationId}
                  onChange={this.onOrganizationChange}
                  search={this.onOrganizationSearch}
                  options={orgOptions}
                  validation={inviteAdminValidation.ORGANIZATION}
                  loading={Record.isRecordLoading(
                    this.props.inviteAdmin.orgOptions
                  )}
                  clear={this.organizationClear}
                />
              )}

            {params.roles === roles.CAMPAIGN_ADMIN && (
              <CommonForm.Autocomplete
                id="invitePanelCampaign"
                label="Campaign"
                value={get(params, 'roleDetail.campaignId[0]')}
                onChange={this.onCampaignChange}
                search={this.onCampaignSearch}
                options={campaignOptions}
                validation={inviteAdminValidation.CAMPAIGN}
                loading={Record.isRecordLoading(
                  this.props.inviteAdmin.campaignOptions
                )}
                clear={this.campaignClear}
              />
            )}

            {params.roles === roles.EVENT_ADMIN && (
              <CommonForm.Autocomplete
                id="invitePanelEvent"
                label="Event"
                value={get(params, 'roleDetail.eventId[0]')}
                onChange={this.onEventChange}
                search={this.onEventSearch}
                options={eventOptions}
                validation={inviteAdminValidation.EVENT}
                loading={Record.isRecordLoading(
                  this.props.inviteAdmin.eventOptions
                )}
                clear={this.eventClear}
              />
            )}

            {params.roles === roles.EVENT_ORGANIZER_ADMIN && (
              <CommonForm.Autocomplete
                id="invitePanelEventOrganizer"
                label="Event Organizer"
                value={get(params, 'roleDetail.eventOrganizerId[0]')}
                onChange={this.onEventOrganizerChange}
                search={this.onEventOrganizerSearch}
                options={eventOrganizerOptions}
                validation={inviteAdminValidation.EVENT_ORGANIZER}
                loading={Record.isRecordLoading(
                  this.props.inviteAdmin.eventOrganizerOptions
                )}
                clear={this.eventOrganizerClear}
              />
            )}

            <Form.Field width={3}>
              <div className="field">
                <label className="input-sub-bottom">&nbsp;</label>
                <Button
                  id="invitePanelInviteButton"
                  primary
                  onClick={this.onInviteClick}
                  loading={Record.isRecordLoading(this.props.inviteAdminRecord)}
                  disabled={
                    Record.isRecordLoading(this.props.inviteAdminRecord) ||
                    !this.props.isInvitePanelValid
                  }
                >
                  {I18n.t('account.invite-admin.invite-button')}
                </Button>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapState = ({ account, session }) => {
  return {
    inviteAdmin: account.inviteAdmin,
    inviteAdminRecord: account.inviteAdminRecord,
    isInvitePanelValid: account.isInvitePanelValid,
    isOrganizationAdmin: session.isOrganizationAdmin,
    isSystemAdmin: session.isSystemAdmin,
    organization: session.organization
  };
};

const mapDispatch = (dispatch) => {
  return {
    onInviteAdminParamChange(path, value) {
      dispatch(onInviteAdminParamChange(path, value));
    },
    onInviteAdminClick(record) {
      dispatch(inviteAdmin(record));
    },
    getOrgOptions(searchtext) {
      dispatch(getOrgOptions(searchtext));
    },
    getCampaignOptions(searchtext) {
      dispatch(getCampaignOptions(searchtext));
    },
    getEventOptions(searchtext) {
      dispatch(getEventOptions(searchtext));
    },
    getEventOrganizerOptions(searchtext) {
      dispatch(getEventOrganizerOptions(searchtext));
    },
    onInvitePanelValidationChange(value) {
      dispatch(onInvitePanelValidationChange(value));
    }
  };
};
export default connect(mapState, mapDispatch)(InviteAdmin);
