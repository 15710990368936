export default Object.assign(String.prototype, {
  addFieldName(fieldName) {
    return `${this}?fieldName=${fieldName}`;
  },
  addFieldAggregate(fieldAggregate) {
    return `${this}&fieldAggregate=${fieldAggregate}`;
  },
  // Index will be ignored
  // TODO: Rewrite
  addFilter(key, operator, value, index) {
    const url = new URL(`https://localhost/${this}`);
    const params = new URLSearchParams(url.search);
    const maxIndex = Math.max(
      ...Array.from(params.keys())
        .filter((key) => key.startsWith('filters'))
        .map((key) => parseInt(key.match(/\[(\d+)\]/)[1]))
    );

    const newIndex = maxIndex < 0 ? 0 : maxIndex + 1;

    return `${this}&filters[${newIndex}].key=${key}&filters[${newIndex}].operator=${operator}&filters[${newIndex}].value=${value}`;
  }
});
