import update from '../../../helpers/update';
import { FUNDRAISER_ENTITY, DONOR_ENTITY } from "../constants";
import { TOGGLE_COLUMNS_CHANGE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, MESSAGE_CLEAR, GET_ORGANIZATION_OPTIONS_REQUESTED, GET_ORGANIZATION_OPTIONS_SUCCESS, GET_ORGANIZATION_OPTIONS_FAILURE, GET_CAMPAIGN_OPTIONS_REQUESTED, GET_CAMPAIGN_OPTIONS_SUCCESS, GET_CAMPAIGN_OPTIONS_FAILURE, GET_EVENT_OPTIONS_REQUESTED, GET_EVENT_OPTIONS_SUCCESS, GET_EVENT_OPTIONS_FAILURE, GET_EVENT_ORGANIZER_OPTIONS_REQUESTED, GET_EVENT_ORGANIZER_OPTIONS_SUCCESS, GET_EVENT_ORGANIZER_OPTIONS_FAILURE, INVITE_ADMIN_PANEL_VISIBILITY_CHANGE, ACTIVE_TAB_SET, INVITE_ADMIN_REQUESTED, INVITE_ADMIN_SUCCESS, INVITE_ADMIN_FAILURE, ON_INVITE_PANEL_VALIDATION_CHANGE, INVITE_ADMIN_PARAM_CHANGE } from "./accountTypes";
import * as entityHandlers from '../../../modules/bin/entityHandlers';

export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );
// export const getExportFile = (entity: any, request: any, fileName?: string) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName
//   );
// };

export const getMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'account',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    'account',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: any,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getListData(listKey, id, newRequest, true);
};

export const getFundraiserMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    FUNDRAISER_ENTITY,
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getFundraiserListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    FUNDRAISER_ENTITY,
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getFundraiserListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: any,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getFundraiserListData(listKey, id, newRequest, true);
};

export const getDonorMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    DONOR_ENTITY,
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getDonorListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate?: boolean,
  cachedData?: any
) =>
  entityHandlers.getListData(
    DONOR_ENTITY,
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getDonorListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: any,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getDonorListData(listKey, id, newRequest, true);
};

export const clearRecordMessages = (record: any) => ({
  type: MESSAGE_CLEAR,
  payload: { key: record }
});

export const getOrgOptions = (searchText: any) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: []
  };

  addCustomTextFilter(request, searchText);

  return entityHandlers.getListData(
    'organization',
    'inviteAdmin.orgOptions',
    null,
    GET_ORGANIZATION_OPTIONS_REQUESTED,
    GET_ORGANIZATION_OPTIONS_SUCCESS,
    GET_ORGANIZATION_OPTIONS_FAILURE,
    request
  );
};

export const getCampaignOptions = (searchText: any) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'status',
        operator: 'EqualTo',
        value: 1
      }
    ]
  };

  addCustomTextFilter(request, searchText);

  return entityHandlers.getListData(
    'page/campaign',
    'inviteAdmin.campaignOptions',
    null,
    GET_CAMPAIGN_OPTIONS_REQUESTED,
    GET_CAMPAIGN_OPTIONS_SUCCESS,
    GET_CAMPAIGN_OPTIONS_FAILURE,
    request
  );
};

export const getEventOptions = (searchText: any) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'status',
        operator: 'EqualTo',
        value: 1
      }
    ]
  };

  addCustomTextFilter(request, searchText);

  return entityHandlers.getListData(
    'page/event',
    'inviteAdmin.eventOptions',
    null,
    GET_EVENT_OPTIONS_REQUESTED,
    GET_EVENT_OPTIONS_SUCCESS,
    GET_EVENT_OPTIONS_FAILURE,
    request
  );
};

export const getEventOrganizerOptions = (searchText: any) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: []
  };

  return entityHandlers.getListData(
    'EventOrganizer',
    'inviteAdmin.eventOrganizerOptions',
    null,
    GET_EVENT_ORGANIZER_OPTIONS_REQUESTED,
    GET_EVENT_ORGANIZER_OPTIONS_SUCCESS,
    GET_EVENT_ORGANIZER_OPTIONS_FAILURE,
    request
  );
};

export const changeInviteAdminPanelVisibility = (visible: any) => ({
  type: INVITE_ADMIN_PANEL_VISIBILITY_CHANGE,
  payload: visible
});
export const setView = (view: any) => ({ type: ACTIVE_TAB_SET, payload: view });
export const inviteAdmin = (record: any) =>
  entityHandlers.createRecord(
    'account',
    INVITE_ADMIN_REQUESTED,
    INVITE_ADMIN_SUCCESS,
    INVITE_ADMIN_FAILURE,
    record,
    null,
    'inviteAdminRecord'
  );
export const onInvitePanelValidationChange = (value: any) => ({
  type: ON_INVITE_PANEL_VALIDATION_CHANGE,
  payload: {
    value: value
  }
});
export const onInviteAdminParamChange = (path: any, value: any) => ({
  type: INVITE_ADMIN_PARAM_CHANGE,
  payload: {
    path: path,
    value: value
  }
});

export const getSupportedCommands = () => {
  return ['id'];
}

export const addCustomTextFilter = (request: any, searchQuery: string) => {
  if (!searchQuery || searchQuery.length === 0 || !request) {
    return;
  }
  const customCommands = searchQuery.match(/(\w+)\/(\d+)/g) || [];
    if (customCommands.length > 0) {
      const supportedCommands = getSupportedCommands();
      request.filters = request.filters || [];      
      customCommands.forEach((command) => {
        searchQuery = searchQuery.replace(command, '');
        const [key, value] = command.split('/');
        if (!key || !value) {
          return;
        }
        if (!supportedCommands.includes(key)) {
          return;
        }
        request.filters = request.filters.filter((filterRecord: { key: string; operator: string; value: any }) => filterRecord.key !== key);
        request.filters.push({ key, operator: 'EqualTo', value});
      });
      searchQuery = searchQuery.trim();
    }
    request.text = searchQuery;
};