import React, {
  FunctionComponent,
  useState,
  useEffect,
} from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
} from "@stripe/stripe-js";
import { Form } from "semantic-ui-react";

import "./index.css";

interface CardInputStatus {
  number?: StripeCardNumberElementChangeEvent,
  expiry?: StripeCardExpiryElementChangeEvent,
  cvc?: StripeCardCvcElementChangeEvent,
}

interface CardInputProps {
  path: string;
  onChange: (path: string, data: any) => void;
}

export const CardInput: FunctionComponent<CardInputProps> = (props) => {
  const {
    path,
    onChange: handleChange,
  } = props;

  const [status, setStatus] = useState<CardInputStatus>({});

  useEffect(() => {
    if (handleChange) {
      handleChange(
        path,
        status,
      );
    }
  }, [status]);

  return (
    <>
      <Form.Field>
        <div className="payment-input-container">
          <label className="payment-input-label">Card Number</label>
          <CardNumberElement
            onChange={(event) => {
              setStatus({
                ...status,
                number: event,
              });
            }}
          />
          {status?.number?.error && (
            <span className="payment-input-error">
              {status.number.error.message}
            </span>
          )}
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <div className="payment-input-container">
            <label className="payment-input-label">Expiry Date</label>
            <CardExpiryElement
              onChange={(event) => {
                setStatus({
                  ...status,
                  expiry: event,
                });
              }}
            />
            {status?.expiry?.error && (
              <span className="payment-input-error">
                {status.expiry.error.message}
              </span>
            )}
          </div>
          <div className="payment-input-container">
            <label className="payment-input-label">CVC</label>
            <CardCvcElement
              options={{
                placeholder: "123",
              }}
              onChange={(event) => {
                setStatus({
                  ...status,
                  cvc: event,
                });
              }}
            />
            {status?.cvc?.error && (
              <span className="payment-input-error">
                {status.cvc.error.message}
              </span>
            )}
          </div>
        </div>
      </Form.Field>
    </>
  );
};

export default CardInput;
